import { BsTrash } from "react-icons/bs";
import TextInput from "../../inputComponents/TextInput";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import ChildCard from "./ChildCard";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const ParentDropper = ({ item, handleDelete, setUpdater }) => {

    const { t } = useTranslation()

    const [name, setName] = useState({
        name: "",
        sort: ""
    })

    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    const handleSetter = (value, inputName) => {
        setName(prev => ({ ...prev, [inputName]: value }))
    }

    const handleUpdate = () => {
        axiosAdmin.put(`/category/${item.id}`, name)
            .then((res) => {
                successToast("Success");
                setUpdater(res)
            })
            .catch(() => {
                errorToast("Error");
            })
    }

    useEffect(() => {
        setName({
            name: item.name,
            sort: item.sort
        })
    }, [item])

    return (
        <div className="rounded-lg w-full border border-slate-300 shadow-md p-5 bg-white">
            <div className="flex cursor-pointer bg-slate-300 p-1 rounded-lg
            flex-col gap-2"
            >
                <div className="w-full grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                    <div>
                        <TextInput
                            name={t("depth")}
                            placeholder={t("depth")}
                            inputName={"deep"}
                            value={item.deep}
                        />
                    </div>
                    <div>
                        <TextInput
                            name={t("index")}
                            placeholder={t("index")}
                            inputName={"sort"}
                            value={item.sort}
                            inputSetter={handleSetter}
                        />
                    </div>
                    <div>
                        <TextInput
                            name={t("language")}
                            placeholder={"GE,EN,RU"}
                            inputName={"language"}
                            value={item.language}
                            inputSetter={handleSetter}
                        />
                    </div>
                    <div className="w-full col-span-3 max-sm:col-span-1">
                        <TextInput
                            name={t("name")}
                            placeholder={t("name")}
                            inputName={"name"}
                            value={item.name}
                            inputSetter={handleSetter}
                        />
                    </div>
                </div>
                <div className="flex gap-2 justify-center mt-7">
                    <button className="w-full p-2 rounded-lg bg-green-400 text-white tex-sm font-bold"
                        onClick={handleUpdate}
                    >
                        {t("update")}
                    </button>
                    <div className="w-10 p-2 rounded-lg bg-red-400 text-white tex-sm font-bold"
                        onClick={() => { setShowContinue(true) }}
                    >
                        <BsTrash className="text-white w-full h-full" />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-5 items-center justify-between cursor-pointer mt-5"
            >
                {item.children.length !== 0 &&
                    item.children.map((innerItem) => {
                        return (
                            <ChildCard
                                key={innerItem.id}
                                innerItem={innerItem}
                                handleDelete={handleDelete}
                                setUpdater={setUpdater}
                            />
                        )
                    })
                }

            </div>

            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDelete}
                setShowContinue={setShowContinue}
                id={item.id}
            />
        </div>
    )
}

export default ParentDropper