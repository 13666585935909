import LocationSelector from "../../locationSelector/LocationSelector";
import { useTranslation } from "react-i18next";

const HomeCountry = () => {
  const { t } = useTranslation()
  return (
    <div className="p-11 flex flex-col gap-11 max-sm:p-5 max-w-[1920px] bg-white rounded-3xl max-sm:gap-5 w-full">

      <h1 className="text-3xl max-sm:text-xl font-bold w-full text-center">
        {t('chooseCountryText1')}
        <span className="text-[#0d84ff]"> {t('chooseCountryText2')}</span>
      </h1>

      <LocationSelector />

    </div>
  );
};

export default HomeCountry;