import { BsTrash } from "react-icons/bs";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const ChildCard = ({ innerItem, handleDelete, setUpdater }) => {
    const { t } = useTranslation()
    const [name, setName] = useState({
        name: "",
        sort: ""
    })

    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    const handleSetter = (value, inputName) => {
        setName(prev => ({ ...prev, [inputName]: value }))
    }

    const handleUpdate = () => {
        axiosAdmin.put(`/category/${innerItem.id}`, name)
            .then((res) => {
                successToast("Success");
                setUpdater(res);
            })
            .catch(() => {
                errorToast("Error");
            })
    }

    useEffect(() => {
        setName({
            name: innerItem.name,
            sort: innerItem.sort
        })
    }, [innerItem])

    return (
        <div className="w-full bg-green-100 p-1 rounded-lg flex flex-col gap-2">
            <div className="w-full grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                <div>
                    <TextInput
                        name={t("depth")}
                        placeholder={t("depth")}
                        inputName={"deep"}
                        value={innerItem.deep}
                    />
                </div>
                <div>
                    <TextInput
                        name={t("index")}
                        placeholder={t("index")}
                        inputName={"sort"}
                        value={innerItem.sort}
                        inputSetter={handleSetter}
                    />
                </div>
                <TextInput
                    name={t("language")}
                    placeholder={"GE,EN,RU"}
                    inputName={"language"}
                    value={innerItem.language}
                    inputSetter={handleSetter}
                />
                <div className="w-full col-span-3 max-sm:col-span-1">
                    <TextInput
                        name={t("name")}
                        placeholder={t("name")}
                        inputName={"name"}
                        value={innerItem.name}
                        inputSetter={handleSetter}
                    />
                </div>
            </div>
            <div className="flex gap-2 justify-center mt-7">
                <button className="w-full p-2 rounded-lg bg-green-400 text-white tex-sm font-bold"
                    onClick={handleUpdate}
                >
                    {t("update")}
                </button>
                <div className="w-10 p-2 rounded-lg bg-red-400 text-white tex-sm font-bold"
                    onClick={() => { setShowContinue(true) }}
                >
                    <BsTrash className="text-white w-full h-full" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDelete}
                setShowContinue={setShowContinue}
                id={innerItem.id}
            />
        </div>
    )
}

export default ChildCard