import { useContext, useEffect, useState } from "react";
import axiosClient from "../../../api/api";
import { BiChevronRight } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import { PrimaryContext } from "../../MainContext/MainContext";
import { useTranslation } from "react-i18next";
import { errorToast } from "../../../adminPanel/components/toastAlerts/ToastAlerts";

const HomeServices = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const { apiLanguage, selectedCountries } = useContext(PrimaryContext);
  const [isLoading, setIsLoading] = useState(true);
  const dummyArray = Array.from({ length: 16 });

  const handleChooseService = (e) => {
    if (
      selectedCountries.country === "" ||
      selectedCountries.region === "" ||
      selectedCountries.country === ""
    ) {
      e.preventDefault();
      errorToast(t("chooseCountriesWarning"));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/category?language=${apiLanguage}`)
      .then(({ data }) => {
        setIsLoading(false);
        setCategories(data);
      })
      .catch(() => {});
  }, [apiLanguage]);

  return (
    <div className="w-full max-w-[1920px]" id="services">
      <div className="w-full flex flex-col gap-11">
        <div>
          <h1 className="text-3xl max-sm:text-xl font-bold w-full text-center">
            <span className="text-[#0d84ff]">{t("our")} </span>
            {t("services")}
          </h1>

          <p className="text-center mt-3 font-semibold text-2xl">{t("pleaseChooseCountry")}</p>
        </div>

        {isLoading ? (
          <div className="grid grid-cols-4 gap-5 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            {dummyArray.map((_, index) => {
              return (
                <div
                  className="animate-pulse dark-gray-bg w-full h-[180px] rounded-xl"
                  key={index}
                ></div>
              );
            })}
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-5 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            {categories.map((item) => {
              return (
                <Link
                  className="w-full col-span-1 row-span-1 inline-block"
                  key={item.id}
                  to={`/${item.id}`}
                  onClick={handleChooseService}
                >
                  <div
                    className="p-5 cursor-pointer rounded-lg dark-gray-bg hover:dark-gray-bg-hover h-full
                   flex justify-between flex-col"
                  >
                    <div className="flex gap-2 flex-col">
                      <div className="rounded-full">
                        <BsCartCheck className="text-[#0d84ff] font-bold text-2xl" />
                      </div>
                      <div>
                        <p className={"text-md text-slate-500 font-semibold"}>
                          {item.name}
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex justify-end mt-2">
                      <div
                        className="w-6 h-6 bg-[#0d84ff] rounded-full
                  flex items-center justify-center"
                      >
                        <BiChevronRight className="text-xl text-white font-bold" />
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeServices;
