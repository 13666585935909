import QuestionMark from "./QuestionMark";

const JustText = ({ item }) => {

  return (
    <div className="w-full flex items-center justify-between p-2 bg-slate-100 rounded-2xl mb-3">
      <p className="font-bold text-sm">{item?.name}</p>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default JustText;
