import TextInput from "../../inputComponents/TextInput";
import TextArea from "../../inputComponents/TextArea";
import { BsX } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import { useEffect, useState } from "react";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import TextEditor from "../../inputComponents/TextEditor";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import { FaRegSave } from "react-icons/fa";
import axiosAdmin from "../../../../api/apiAdmin";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import FileInput from "../../inputComponents/FileInput";

const InnerQuestionCard = ({
  item,
  handleDeleteQuestion1,
  parentInnerId,
  handleAddQuestionCard,
  questionsId,
  dataUpdater,
  vcrc,
  needCityWithPrice = false,
  cities,
  setVcrc,
}) => {
  const { t } = useTranslation();
  const [question, setQuestions] = useState([]);
  const [headInfo, setHeadInfo] = useState([]);
  //show continue
  const [showContinue, setShowContinue] = useState(false);
  const [showContinue1, setShowContinue1] = useState(false);
  //delete id
  const [deleteId1, setDeleteId1] = useState(null);

  //delete item from list
  const handleDeleteQuestion = (id) => {
    if (question.filter((item) => item.id === id)[0].new) {
      setQuestions((prev) =>
        prev.filter((item) => {
          return item.id !== id && item;
        })
      );
    } else {
      axiosAdmin
        .delete(`/serviceRow/${id}`)
        .then(({ data }) => {
          dataUpdater(Date.now());
        })
        .catch((err) => {});
    }
  };

  //add item in list
  const handleAddQuestion = (id) => {
    setQuestions((prev) => [
      ...prev.slice(0, id + 1),
      {
        id: Date.now(),
        questionText: "",
        questionImg: "",
        name: "",
        type: "",
        price: "",
        previousId: id,
        new: true,
      },
      ...prev.slice(id + 1),
    ]);
  };

  const handleItemStart = () => {
    setQuestions((prev) => [
      ...prev,
      {
        id: Date.now(),
        questionText: "",
        questionImg: "",
        name: "",
        type: "",
        price: "",
        previousId:
          question.length === 0 ? 0 : question[question.length - 1].id,
        new: true,
      },
    ]);
  };

  function handleUpdateTable(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("service_id", questionsId);
    if (item.new) {
      formData.append("sort", item.previousId);
    }

    axiosAdmin
      .post(item.new ? `/serviceTable` : `/serviceTable/${item.id}`, formData)
      .then((res) => {
        dataUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  }

  function handleUpdateRow(event, previousRowId, isNew, rowId) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    if (vcrc) {
      axiosAdmin
        .post(`/priceVariation/${rowId}`, formData)
        .then((res) => {
          dataUpdater(res);
          successToast("Success");
        })
        .catch((err) => {
          errorToast("Error");
        });
    } else {
      formData.append("service_id", questionsId);
      formData.append("table_id", item.id);
      if (isNew) {
        formData.append("sort", previousRowId);
      }

      axiosAdmin
        .post(isNew ? `/serviceRow` : `/serviceRow/${rowId}`, formData)
        .then((res) => {
          dataUpdater(res);
          successToast("Success");
        })
        .catch((err) => {
          errorToast("Error");
        });
    }
  }

  useEffect(() => {
    setHeadInfo(item);
    setQuestions(Object.values(item.service_rows));
  }, [item]);

  return (
    <div className="flex flex-col mt-5 rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
      <form
        onSubmit={(e) => {
          handleUpdateTable(e);
        }}
        encType="multipart/form-data"
        className="w-full"
      >
        <div className="mb-5">
          <TextEditor name={`description`} value={headInfo.description} />
        </div>
        <div className="grid grid-cols-1 border-b border-slate-300 mb-5 pb-5">
          <FileInput inputName={`img`} name={t("img")} />
          <div className="flex gap-2 w-full mt-2">
            <div className="w-full">
              <TextInput
                name={t("title")}
                inputName={`name`}
                placeholder={t("title")}
                value={headInfo.name}
              />
            </div>
            <div className="flex items-center justify-end  gap-2 shrink-0">
              <div
                className="w-8 h-8 p-2 bg-green-400 rounded-full mt-6 cursor-pointer shrink-0"
                onClick={() => {
                  handleAddQuestionCard(parentInnerId, "between");
                }}
              >
                <BiPlus className="text-white h-full w-full" />
              </div>
              <button
                type="submit"
                className="w-8 h-8 p-2 bg-purple-400 rounded-full mt-6 cursor-pointer shrink-0"
              >
                <FaRegSave className="text-white h-full w-full" />
              </button>
              <div
                className="w-8 h-8 p-2 bg-red-400 rounded-full mt-6 cursor-pointer shrink-0"
                onClick={() => {
                  setShowContinue(true);
                }}
              >
                <BsX className="text-white h-full w-full" />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteQuestion1}
        setShowContinue={setShowContinue}
        id={parentInnerId}
      />
      <div className="w-full">
        {question?.map((item, index) => {
          return (
            <form
              key={item.id}
              onSubmit={(e) => {
                handleUpdateRow(
                  e,
                  item.previousId,
                  item.new,
                  vcrc ? item.variation_id : item.id
                );
              }}
              encType="multipart/form-data"
              className="w-full"
            >
              <div className="w-full flex flex-col gap-2">
                <div className="flex items-center justify-start gap-2">
                  <div>
                    <p className="text-slate-500 text-2xl font-bold">
                      {index + 1}
                    </p>
                  </div>
                  <div
                    className="w-8 h-8 p-2 bg-green-400 rounded-full cursor-pointer shrink-0"
                    onClick={() => {
                      handleAddQuestion(index);
                    }}
                  >
                    <BiPlus className="text-white h-full w-full" />
                  </div>
                  <button
                    type="submit"
                    className="w-8 h-8 p-2 bg-violet-400 rounded-full cursor-pointer shrink-0"
                  >
                    <FaRegSave className="text-white h-full w-full" />
                  </button>
                  <div
                    className="w-8 h-8 p-2 bg-red-400 rounded-full cursor-pointer shrink-0"
                    onClick={() => {
                      setDeleteId1(item.id, item.new);
                      setShowContinue1(true);
                    }}
                  >
                    <BsX className="text-white h-full w-full" />
                  </div>
                </div>
                <div className="mb-2">
                  <TextEditor name={`description`} value={item.description} />
                </div>
                <div className="grid grid-cols-1 gap-2 border-b border-slate-300 mb-5 pb-5">
                  <FileInput inputName={`img`} name={t("img")} />
                  <TextArea
                    name={t("name")}
                    inputName={`name`}
                    value={item.name}
                  />

                  <div className="w-full grid grid-cols-2 gap-2">
                    <BasicSelectComponent
                      name={t("type")}
                      options={[
                        {
                          name: `${t("check")} ☑`,
                          value: 1,
                        },
                        {
                          name: `${t("radio")} 🔘`,
                          value: 2,
                        },
                        {
                          name: t("select"),
                          value: 3,
                        },
                        {
                          name: t("plus"),
                          value: 4,
                        },
                        {
                          name: t("plus2"),
                          value: 5,
                        },
                        {
                          name: t("plus3"),
                          value: 6,
                        },
                        {
                          name: t("plus4"),
                          value: 7,
                        },
                        {
                          name: t("date"),
                          value: 8,
                        },
                        {
                          name: t("date2"),
                          value: 9,
                        },
                        {
                          name: t("time"),
                          value: 10,
                        },
                        {
                          name: t("imgUpload"),
                          value: 11,
                        },
                        {
                          name: t("textInput"),
                          value: 13,
                        },
                        {
                          name: t("onlyText"),
                          value: 14,
                        },
                        {
                          name: t("dropdownStart"),
                          value: 15,
                        },
                        {
                          name: t("dropdownEnd"),
                          value: 16,
                        },
                        {
                          name: t("onlyPlus"),
                          value: 17,
                        },
                        {
                          name: `${t("onlyPlus")} ${t("next")}`,
                          value: 18,
                        },
                        {
                          name: `${t("plus4")} ${t("next")}`,
                          value: 19,
                        },
                        {
                          name: `${t("plus")} ${t("next")}`,
                          value: 20,
                        },
                        {
                          name: `${t("contact")}`,
                          value: 21,
                        },
                        {
                          name: `${t("link")}`,
                          value: 22,
                        },
                        {
                          name: `${t("ytLink")}`,
                          value: 23,
                        },
                        {
                          name: `${t("textWithoutColor")}`,
                          value: 24,
                        },
                        {
                          name: `${t("check")} ☑ ₾`,
                          value: 25,
                        },
                        {
                          name: `${t("radio")} 🔘 ₾`,
                          value: 26,
                        },
                      ]}
                      inputName={`type`}
                      defaultValue={item.type}
                    />
                    <div className="flex items-center gap-2">
                      <div className="w-full">
                        <TextInput
                          name={t("price")}
                          inputName={`price`}
                          placeholder={t("priceMap")}
                          value={item.price}
                        />
                      </div>
                      <div className="w-full mt-5">
                        {needCityWithPrice && (
                          <BasicSelectComponent
                            options={cities}
                            inputName={"crc_id"}
                            defaultValue={0}
                            retrieveValue={setVcrc}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        })}
      </div>
      <div
        className="rounded-lg p-3 text-white text-sm bg-green-400 self-start cursor-pointer"
        onClick={handleItemStart}
      >
        + ველი
      </div>
      <ContinueComponent
        showFlag={showContinue1}
        actionFunction={handleDeleteQuestion}
        setShowContinue={setShowContinue1}
        id={deleteId1}
      />
    </div>
  );
};

export default InnerQuestionCard;
