import { BsTrash } from "react-icons/bs";
import { BiSave } from "react-icons/bi"

import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import FileInput from "../../inputComponents/FileInput";
import axiosAdmin from "../../../../api/apiAdmin";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const CountryDropper = ({ item, handleDelete, justCountries, justRegions, setUpdater }) => {
    const { t } = useTranslation()

    const [selectId, setSelectId] = useState({
        crc_id: null
    });

    const handleCRCId = (value, inputName) => {
        setSelectId({ crc_id: value })
    }

    //change category data
    function handleSubmitCountry(event, id) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        formData.append("type", "country");

        axiosAdmin.post(`/crc/${id}`, formData)
            .then((res) => {
                setUpdater(res)
                successToast("Success");
            })
            .catch((err) => {
                errorToast("Error");
            });
    }

    function handleSubmitRegion(event, id) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        formData.append("crc_id", selectId.crc_id);

        axiosAdmin.post(`/crc/${id}`, formData)
            .then((res) => {
                setUpdater(res)
                successToast("Success");
            })
            .catch((err) => {
                errorToast("Error");
            });
    }

    function handleSubmitCity(event, id) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        formData.append("crc_id", selectId.crc_id);

        axiosAdmin.post(`/crc/${id}`, formData)
            .then((res) => {
                setUpdater(res)
                successToast("Success");
            })
            .catch((err) => {
                errorToast("Error");
            });
    }

    //show contnue
    const [showContinue, setShowContinue] = useState(false);
    const [showContinue1, setShowContinue1] = useState(false);
    const [showContinue2, setShowContinue2] = useState(false);

    //delete id
    const [deleteId1, setDeleteId1] = useState(null);
    const [deleteId2, setDeleteId2] = useState(null);

    return (
        <div className="rounded-lg w-full border border-slate-300 shadow-md p-5 bg-white">
            <form
                onSubmit={(e) => { handleSubmitCountry(e, item.id) }}
                encType="multipart/form-data"
            >
                <div className="flex items-center justify-between cursor-pointer bg-slate-100 p-1 rounded-lg max-sm:flex-col">
                    <div className="w-full grid grid-cols-3 max-md:grid-cols-2 gap-2 justify-end items-end">
                        <TextInput
                            inputName={"name"}
                            inputSetter={() => { }}
                            placeholder={t("name")}
                            value={item.name}
                        />
                        <FileInput
                            inputName={"img"}
                            name={t("img")}
                        />
                        <TextInput
                            inputName={"currency"}
                            inputSetter={() => { }}
                            placeholder={t("currency")}
                            value={item.currency}
                        />
                        <div className="h-10">
                            <img src={`${process.env.REACT_APP_API_URL}/storage/${item.img}`} alt="#" className="w-auto h-full" />
                        </div>
                    </div>
                    <div className="flex gap-2 h-10 px-2 items-center justify-center">
                        <button type="submit">
                            <BiSave className="text-green-500 w-7 h-7" />
                        </button>
                        <BsTrash className="text-red-500 w-6 h-6" onClick={() => { setShowContinue(true) }} />
                    </div>
                </div>
                <ContinueComponent
                    showFlag={showContinue}
                    actionFunction={handleDelete}
                    setShowContinue={setShowContinue}
                    id={item.id}
                />
            </form>
            <div className="flex flex-col gap-5 items-center justify-between cursor-pointer mt-5">
                {item.children.length !== 0 &&
                    item.children.map((innerItem) => {
                        return (
                            <div className="w-full" key={innerItem.id}>
                                <form
                                    onSubmit={(e) => { handleSubmitRegion(e, innerItem.id) }}
                                    encType="multipart/form-data"
                                >
                                    <div className="flex items-center justify-between cursor-pointer bg-green-100 p-1 rounded-lg max-sm:flex-col">
                                        <div className="w-full grid grid-cols-2 max-md:grid-cols-1 gap-2 justify-end items-end">
                                            <TextInput
                                                inputName={"name"}
                                                inputSetter={() => { }}
                                                placeholder={t("name")}
                                                value={innerItem.name}
                                            />
                                            <SelectInputValueId
                                                options={justCountries}
                                                inputName={"crc_id"}
                                                inputSetter={handleCRCId}
                                            />
                                        </div>
                                        <div className="flex gap-2 h-10 px-2 items-center justify-center">
                                            <button type="submit" >
                                                <BiSave className="text-green-500 w-7 h-7" />
                                            </button>
                                            <BsTrash className="text-red-500 w-6 h-6" onClick={() => { setDeleteId1(innerItem.id); setShowContinue1(true) }} />
                                        </div>
                                    </div>
                                </form>
                                <div className="flex flex-col gap-5 items-center justify-between cursor-pointer mt-5">
                                    {innerItem.children.length !== 0 &&
                                        innerItem.children.map((innerItem2) => {
                                            return (
                                                <form
                                                    onSubmit={(e) => { handleSubmitCity(e, innerItem2.id) }}
                                                    encType="multipart/form-data"
                                                    key={innerItem2.id}
                                                    className="w-full"
                                                >
                                                    <div className="w-full flex items-center justify-between cursor-pointer bg-red-100 p-1 rounded-lg 
                                                    max-sm:flex-col">
                                                        <div className="w-full grid grid-cols-2 max-md:grid-cols-1 gap-2 justify-end items-end" >
                                                            <TextInput
                                                                inputName={"name"}
                                                                inputSetter={() => { }}
                                                                placeholder={t("name")}
                                                                value={innerItem2.name}
                                                            />
                                                            <SelectInputValueId
                                                                options={justRegions}
                                                                inputName={"crc_id"}
                                                                inputSetter={handleCRCId}
                                                                value={innerItem2.name}
                                                            />
                                                        </div>
                                                        <div className="flex gap-2 h-10 px-2 items-center justify-center">
                                                            <button type="submit" >
                                                                <BiSave className="text-green-500 w-7 h-7" />
                                                            </button>
                                                            <BsTrash className="text-red-500 w-6 h-6" onClick={() => { setDeleteId2(innerItem2.id); setShowContinue2(true) }} />
                                                        </div>
                                                    </div>
                                                </form>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <ContinueComponent
                showFlag={showContinue1}
                actionFunction={handleDelete}
                setShowContinue={setShowContinue1}
                id={deleteId1}
            />
            <ContinueComponent
                showFlag={showContinue2}
                actionFunction={handleDelete}
                setShowContinue={setShowContinue2}
                id={deleteId2}
            />
        </div >
    )
}

export default CountryDropper