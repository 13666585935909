import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { BiChevronRight, BiLoaderAlt } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaLink } from "react-icons/fa";
import ContactSection from "../homeComponents/contactUs/ContactSection";

const ServiceOptions = ({ serviceId, setOptionsId }) => {
  const [parentData, setParentData] = useState({});
  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCategories = () => {
    setIsLoading(true);
    axiosClient
      .get(`/category/${serviceId}`)
      .then((res) => {
        setParentData(res.data.category[0]);
        setChildren(res.data.category[0].children);
        setIsLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <section className="min-h-screen">
      <div className="section-inner-cont ">
        <h1 className="text-center text-2xl max-sm:text-[18px] font-bold mt-10 max-sm:mt-5">
          {parentData.name}
        </h1>
        {isLoading ? (
          <div
            className={`w-full flex items-center justify-center ${
              isLoading && "min-h-[200px]"
            }`}
          >
            <BiLoaderAlt className="w-20 h-20 animate-spin text-[#0d84ff]" />
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-5 mt-3 p-2">
            {children.map((item) => {
              return (
                <div key={item.id}>
                  {item.name.split("@").length === 2 ? (
                    <Link
                      to={item.name.split("@")[1]}
                      target="_blank"
                      className="text-base text-[#0d84ff] font-semibold underline"
                    >
                      {item.name.split("@")[0]}
                    </Link>
                  ) : (
                    <>
                      <p className={"text-base text-[#0d84ff] font-semibold "}>
                        {item.name}
                      </p>
                      {isLoading ? (
                        <div
                          className={`w-full flex items-center justify-center ${
                            isLoading && "min-h-[200px]"
                          }`}
                        >
                          <BiLoaderAlt className="w-20 h-20 animate-spin text-[#0d84ff]" />
                        </div>
                      ) : (
                        <div className="w-full mt-5 flex flex-col gap-2">
                          {item.services.map((itemm) => {
                            return (
                              <div key={itemm.id}>
                                {itemm.name.split("@").length === 2 ? (
                                  <Link
                                    to={itemm.name.split("@")[1]?.trim()}
                                    target="_blank"
                                    className="p-3 flex items-center justify-between cursor-pointer rounded-lg bg-white max-sm:flex-col
                                max-sm:justify-end max-sm:items-end"
                                  >
                                    <div className="flex gap-4 items-center max-sm:flex-col max-sm:items-start max-sm:gap-2 max-sm:w-full">
                                      <div className="rounded-full p-2 max-sm:p-0">
                                        <FaLink className="text-[#0d84ff] font-bold text-xl" />
                                      </div>
                                      <div>
                                        <p className={"text-base"}>
                                          {itemm.name.split("@")[0]?.trim()}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="bg-[#0d84ff] w-7 h-7 flex items-center justify-center rounded-full shrink-0">
                                      <BiChevronRight className="text-xl text-white font-bold " />
                                    </div>
                                  </Link>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setOptionsId(itemm.id);
                                      window.scrollTo(0, 0);
                                    }}
                                    className="p-3 flex items-center justify-between cursor-pointer rounded-lg bg-white max-sm:flex-col
                                max-sm:justify-end max-sm:items-end"
                                  >
                                    <div className="flex gap-4 items-center max-sm:flex-col max-sm:items-start max-sm:gap-2 max-sm:w-full">
                                      <div className="rounded-full p-2 max-sm:p-0">
                                        <BsCartCheck className="text-[#0d84ff] font-bold text-xl" />
                                      </div>
                                      <div>
                                        <p className={"text-base"}>
                                          {itemm.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="bg-[#0d84ff] w-7 h-7 flex items-center justify-center rounded-full shrink-0">
                                      <BiChevronRight className="text-xl text-white font-bold " />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <ContactSection />
      </div>
    </section>
  );
};

export default ServiceOptions;
