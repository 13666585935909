import { useState, useEffect, useContext } from "react";
import CounterPart from "./CounterPart";
import QuestionMark from "../QuestionMark";
import { PrimaryContext } from "../../MainContext/MainContext";

const CounterTwo = ({ item, parentId, childId, parentName }) => {
  const [dataValue, setDataValue] = useState([
    {
      id: 1,
      name: "+2",
      value: "",
    },
    {
      id: 2,
      name: "+3",
      value: "",
    },
  ]);

  const [price, setPrice] = useState([]);

  useEffect(() => {
    try {
      const tmp = item?.price.split("-");
      const nmms = tmp[1].split(",");
      setPrice(tmp[0]);
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: item.value,
            name: nmms[index],
          };
        })
      );
    } catch {
      setPrice(0);
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: "0",
            name: "",
          };
        })
      );
    }
  }, [item]);

  const { handleAddChosedData, handleDeleteChosedData } =
    useContext(PrimaryContext);

  useEffect(() => {
    const isValid = !dataValue.some((item) => [0, ""].includes(item.value));
    if (isValid) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: item?.name,
        price: dataValue[0].value * dataValue[1].value * parseFloat(price),
        priceString:
          `${dataValue[0].value} x ${dataValue[1].value} x ${parseFloat(
            price
          )}` +
          ` = ${dataValue[0].value * dataValue[1].value * parseFloat(price)}`,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [dataValue]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 flex-col gap-2"
    >
      <div className="flex items-center gap-2 w-full justify-between">
        <p className="mt-1">{item?.name}</p>
        {(item?.img || item?.description) && (
          <QuestionMark imgLink={item?.img} redactorText={item?.description} />
        )}
      </div>
      <div
        className="flex items-center gap-5 w-full
      max-lg:gap-2 max-lg:grid max-lg:grid-cols-2"
      >
        <CounterPart
          dataValue={dataValue}
          setDataValue={setDataValue}
          addStyle={"max-sm:w-full"}
        />
        <div className="bg-[#F5F5F5] flex items-center rounded-3xl px-4 py-1 lg:min-w-[160px]">
          <p className="text-center w-full">
            {dataValue[0].value * dataValue[1].value * parseFloat(price)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CounterTwo;
