import TextInput from "../../inputComponents/TextInput";
import TextEditor from "../../inputComponents/TextEditor";
import QuestionCard from "./QuestionCard";
import { useState, useEffect, useContext } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const AddQuestions = () => {
  const { t } = useTranslation();
  const [question, setQuestions] = useState([]);
  const [updater, setUpdater] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [cities, setCities] = useState([]);
  const [lastCountries, setLastCountries] = useState([]);
  const [filterData, setFilterData] = useState({
    category_id: null,
    // language: null,
    crc_id: null,
    // name: null,
  });

  const handleFilterData = (value, key) => {
    setFilterData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    axiosAdmin
      .get(
        `/service?` + `crc_id=${filterData.crc_id ? filterData.crc_id : ""}`
        // `&language=${filterData.language ? filterData.language : ""}` +
        // `&name=${filterData.name ? filterData.name : ""}`
      )
      .then(({ data }) => {
        setServiceList(data);
      })
      .catch((err) => {});
  }, [updater, filterData]);

  //add item in list
  const handleAddQuestion = () => {
    setQuestions((prev) => [...prev, { id: Date.now() }]);
  };

  //add item in list
  const handlePushQuestion = () => {
    setQuestions((prev) => [{ id: Date.now() }, ...prev]);
  };

  const handleDeleteQuestion = (id) => {
    setQuestions((prev) =>
      prev.filter((item) => {
        return item.id !== id && item;
      })
    );
  };

  const handleAddNextQuestionCard = (id) => {
    const newDiv = { id: Date.now() };
    const updatedDivs = [...question];
    const index = updatedDivs.findIndex((div) => div.id === id);
    if (index !== -1) {
      updatedDivs.splice(index + 1, 0, newDiv);
      setQuestions(updatedDivs);
    }
  };

  useEffect(() => {
    axiosAdmin
      .get("/crc?type=country")
      .then(({ data }) => {
        setCities(
          data.map((item) => {
            return { name: item.name, value: item.id };
          })
        );
      })
      .catch((err) => {});

    axiosAdmin
      .get("/category?last=1")
      .then(({ data }) => {
        setLastCountries(
          data.map((item) => {
            return { name: item.name, value: item.id, id: item.sort };
          })
        );
      })
      .catch((err) => {});
  }, []);

  function handleSubmitQuestion(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    axiosAdmin
      .post(`/service`, formData)
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  }

  const handleDeleteServiceQuestions = (id) => {
    axiosAdmin
      .delete(`/service/${id}`)
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  //show continue
  const [showContinue, setShowContinue] = useState(false);
  //delete id
  const [deleteId, setDeleteId] = useState(null);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0
        min-h-screen"
    >
      <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5">
        <div className="bg-slate-100 flex flex-col gap-5">
          <div className="gap-2 items-center grid grid-cols-3 max-sm:grid-cols-1 max-md:grid-cols-2">
            {/* <TextInput
              name={t("language")}
              inputName={"language"}
              placeholder={"EN, RU, GE, ..."}
              inputSetter={handleFilterData}
            />
            <TextInput
              name={t("name")}
              inputName={"name"}
              placeholder={t("name")}
              inputSetter={handleFilterData}
            /> */}
            <BasicSelectComponent
              name={t("country")}
              options={cities}
              inputName={"crc_id"}
              retrieveValue={(value) => {
                handleFilterData(value, "crc_id");
              }}
            />
          </div>

          <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md max-h-[500px] overflow-auto">
            {serviceList.map((item) => {
              return (
                <div
                  className="border-b border-slate-300 mb-2 pb-2 flex items-center justify-between"
                  key={item?.id}
                >
                  <p>
                    {item?.sort}. {item?.name} - ({item?.language}) - (
                    {item?.crc !== null && item?.crc?.name})
                  </p>
                  <div className="flex items-center gap-2">
                    <Link to={`../add-questions/${item?.id}`}>
                      <BiPencil className="text-green-500 w-6 h-6" />
                    </Link>
                    <BsTrash
                      className="text-red-500 w-6 h-6 cursor-pointer"
                      onClick={() => {
                        setDeleteId(item?.id);
                        setShowContinue(true);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <ContinueComponent
            showFlag={showContinue}
            actionFunction={handleDeleteServiceQuestions}
            setShowContinue={setShowContinue}
            id={deleteId}
          />
          <form
            onSubmit={(e) => {
              handleSubmitQuestion(e);
            }}
            encType="multipart/form-data"
            className="w-full"
          >
            <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2">
                <TextInput
                  name={t("name")}
                  inputName={"name"}
                  placeholder={t("name")}
                />
                <BasicSelectComponent
                  name={t("parent")}
                  options={lastCountries}
                  inputName={"category_id"}
                />
                <BasicSelectComponent
                  name={t("city")}
                  options={cities}
                  inputName={"crc_id"}
                />
                <TextInput
                  name={t("index")}
                  inputName={"sort"}
                  type="number"
                  placeholder={t("index")}
                />
                <TextInput
                  name={t("email")}
                  inputName={"mail"}
                  placeholder={t("email")}
                />
                <TextInput
                  name={t("language")}
                  inputName={"language"}
                  placeholder={"GE,RU,EN"}
                />
              </div>
              <div className="mt-5">
                <TextEditor name={"description"} />
              </div>
            </div>
            {question.length >= 0 && (
              <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
                <div
                  className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
                  onClick={handlePushQuestion}
                >
                  + {t("dropDown")}
                </div>
                <button
                  type="submit"
                  className="rounded-lg p-3 text-white text-sm bg-green-400"
                >
                  {t("add")}
                </button>
              </div>
            )}

            <div className="w-full">
              {question.map((item, index) => {
                return (
                  <QuestionCard
                    key={item.id}
                    parentId={index}
                    parentInnerId={item.id}
                    handleDeleteQuestion1={handleDeleteQuestion}
                    handleAddNextQuestionCard={handleAddNextQuestionCard}
                  />
                );
              })}
            </div>

            <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
              <div
                className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
                onClick={handleAddQuestion}
              >
                + {t("dropDown")}
              </div>
              <button
                type="submit"
                className="rounded-lg p-3 text-white text-sm bg-green-400"
              >
                {t("add")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AddQuestions;
