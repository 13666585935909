import { useTranslation } from "react-i18next";
import hero from "../../../assets/img/hero.png";
import appleStoreBadge from "../../../assets/img/apple-store-badge.svg";
import googlePlayBadge from "../../..//assets/img/google-play-badge.png";

//icons
import { FaSearch } from "react-icons/fa";

const HomeHead = () => {
  const { t } = useTranslation()
  return (
    <section className="flex justify-center light-blue-bg rounded-3xl max-w-[1920px] w-full">
      <div className="first-section flex items-center p-11 relative max-md:flex-col max-md:gap-5 w-full max-sm:p-5">

        <div className="w-6/12 max-md:w-full max-md:order-2 flex items-center justify-center">
          <div className="w-8/12 max-lg:w-full">
              <div className="flex items-center gap-2 mb-5 max-md:mt-5 max-md:justify-center">
                <img src={googlePlayBadge} alt="#"  className="w-[150px] h-[50px] rounded-xl cursor-pointer"/>
                <img src={appleStoreBadge} alt="#"  className="w-[150px] h-[50px] rounded-xl cursor-pointer"/>
              </div>
            <h1 className="text-3xl max-sm:text-xl font-bold max-md:text-center">
              {t("bannertext1")}
              <span className="text-[#0d84ff]"> {t("bannertext2")}</span>
            </h1>

            <div className="flex mt-4 gap-4">
              <div className="h-10 bg-white overflow-hidden rounded-lg w-full border
            border-[#0d84ff] flex items-center px-4">
                <input type="search" className="h-full border-none outline-none w-full" placeholder={t("findService")} />
                <FaSearch className="text-[#0d84ff] shrink-0" />
              </div>
              <button className="text-white bg-[#0d84ff] rounded-lg px-4 shrink-0">
                {t("search")}
              </button>
            </div>
          </div>

        </div>

        <div className="w-6/12 max-md:w-8/12 flex items-center justify-center max-md:order-1">
          <div >
            <img src={hero} alt="#" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHead;
