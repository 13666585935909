import { useEffect } from "react";
import { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
const CounterPart = ({ dataValue, setDataValue, addStyle }) => {
  const [value1, setValue1] = useState([]);

  const handleValue = (e) => {

    const newValue = [...value1];
    const numberValue = e.target.value;
    if (numberValue.toString()[0] === "0") {
      if (numberValue !== "") {
        newValue[e.target.name].value = parseInt(
          numberValue.toString().substring(1)
        );
      }
    } else {
      if (numberValue !== "") {
        newValue[e.target.name].value = parseInt(numberValue);
      }
    }
    setValue1(newValue);
    setDataValue(newValue);
  };

  const Increment = (Id) => {
    const newValue = [...value1];
    newValue[Id].value =
      newValue[Id].value === "" ? 1 : parseInt(newValue[Id].value) + 1;
    setValue1(newValue);
    setDataValue(newValue);
  };

  const decrement = (Id) => {
    const newValue = [...value1];
    if (newValue[Id].value !== 0) {
      newValue[Id].value =
        newValue[Id].value === "" ? 0 : parseInt(newValue[Id].value) - 1;
      setValue1(newValue);
      setDataValue(newValue);
    }
  };

  useEffect(() => {
    setValue1(dataValue);
  }, [dataValue]);

  return (
    <>
      {value1.map((item, index) => (
        <div
          key={index}
          className={`p-1 bg-[#F5F5F5] flex gap-1 rounded-3xl items-center justify-center lg:min-w-[160px] ${
            addStyle && addStyle
          }`}
        >
          <div className="flex items-center gap-2">
            <div
              className="w-6 h-6 p-1 rounded-full bg-[#0d84ff]"
              onClick={() => decrement(index)}
            >
              <BiMinus className="text-white cursor-pointer w-full h-full" />
            </div>

            <input
              className="w-12 bg-transparent border-none text-center outline-none"
              type="text"
              name={index}
              value={item.value && item.value}
              onChange={(e) => handleValue(e)}
              placeholder="0"
            />
            <p className="text-[12px] text-center">{item.name}</p>

            <div
              className="w-6 h-6 p-1 rounded-full bg-[#0d84ff]"
              onClick={() => Increment(index)}
            >
              <BiPlus className="text-white cursor-pointer w-full h-full" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CounterPart;
