import { useEffect, useState } from "react";
import { errorToast } from "../../../adminPanel/components/toastAlerts/ToastAlerts";

const DateInput = ({ inputSetter, inputValue, addStyle, minValue }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleInput = (e) => {
    const selectedDate = new Date(e.target.value);
    if (selectedDate > new Date(minValue)) {
      inputSetter(e);
    } else {
      errorToast("Date is not available");
    }
  };

  return (
    <input
      onChange={(e) => {
        handleInput(e);
      }}
      min={minValue !== null && minValue}
      value={value}
      type="date"
      className={`rounded-3xl px-4 py-2 bg-[#F5F5F5] flex items-center justify-between gap-2
     outline-none  w-full ${addStyle && addStyle} cursor-pointer`}
    />
  );
};

export default DateInput;
