import { useState, createContext, useEffect } from "react";
import axiosAdmin from "../../../api/apiAdmin";

export const AdminPrimaryContext = createContext(null);

export const AdminContext = ({ children }) => {
    //current user data
    const [user, setUser] = useState({});
    //token for remembering user in browser
    const [token, _setToken] = useState(localStorage.getItem('GADADI_TOKEN_ADM'));
    //check if user is authorized
    const [isAuthorized, setIsAuthorized] = useState(false);

    //handler function for token
    const setToken = (token) => {
        _setToken(token)
        if (token) {
            localStorage.setItem('GADADI_TOKEN_ADM', token);
        } else {
            localStorage.removeItem('GADADI_TOKEN_ADM');
        }
    }

    //get user data if user is authorized
    useEffect(() => {
        axiosAdmin.get('/admin')
            .then(({ data }) => {
                setUser(data);
                setIsAuthorized(true);
            })
    }, [])

    return (
        <AdminPrimaryContext.Provider
            value={{
                user,
                setUser,
                token,
                setToken,
                isAuthorized,
                setIsAuthorized,
            }}>

            {children}
        </AdminPrimaryContext.Provider>
    )
}