import { createContext, useState, useEffect } from "react";
import axiosClient from "../../api/api";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationGe from "./languages/Ge";
import translationEn from "./languages/En";
import translationRu from "./languages/Ru";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    ge: { translation: translationGe },
    RU: { translation: translationRu },
  },
  lng: "ge",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const PrimaryContext = createContext(null);

const MainContext = ({ children }) => {
  //state that stores fields checked data as objects
  const [chosedData, setChosedData] = useState([]);
  //current user data
  const [user, setUser] = useState({});
  //token for remembering user in browser
  const [token, _setToken] = useState(localStorage.getItem("GADADI_TOKEN"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //order service id
  const [orderServiceId, setOrderServiceId] = useState(null);
  //selected countries data
  const [selectedCountries, setSelectedCountries] = useState({});
  //api language
  const [apiLanguage, setApiLanguage] = useState("ge");

  //handler function for token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("GADADI_TOKEN", token);
    } else {
      localStorage.removeItem("GADADI_TOKEN");
    }
  };

  //get user data if user is authorized
  // useEffect(() => {
  //   axiosClient.get("/user").then(({ data }) => {
  //     setUser(data);
  //     setIsAuthorized(true);
  //   });
  // }, []);

  //this function adds new checked fields by user
  const handleAddChosedData = (newData) => {
    const inList = chosedData.filter((item) => {
      return item.id === newData.id;
    }).length;

    if (inList === 1) {
      setChosedData((prev) =>
        prev.map((item) => {
          if (item.id === newData.id) {
            return newData;
          } else {
            return item;
          }
        })
      );
    } else {
      const data = chosedData.slice();
      data.push(newData);
      data.sort((a, b) => a.compareId.localeCompare(b.compareId));
      setChosedData(data);
    }
  };

  //this function updates state and deletes fields that user does not want anymore
  const handleDeleteChosedData = (id) => {
    setChosedData((prev) =>
      prev.filter((item) => {
        return item.id !== id;
      })
    );
  };

  //this function updates state and saves checked fields by user
  const handleUpdateChosedData = (id, newItem) => {
    const tmp = chosedData.map((item) => {
      if (item.id === id) {
        return newItem;
      } else {
        return item;
      }
    });
    setChosedData(tmp);
  };

  //language switcher
  useEffect(() => {
    i18n.changeLanguage(apiLanguage);
  }, [apiLanguage]);

  useEffect(() => {
    const countryData = JSON.parse(localStorage.getItem("GADADI_COUNTRY"));
    if (countryData?.countryId) {
      setSelectedCountries(countryData);
    }
  }, []);

  return (
    <PrimaryContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthorized,
        setIsAuthorized,
        chosedData,
        setChosedData,
        handleAddChosedData,
        handleDeleteChosedData,
        handleUpdateChosedData,
        orderServiceId,
        setOrderServiceId,
        selectedCountries,
        setSelectedCountries,
        apiLanguage,
        setApiLanguage,
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
};

export { PrimaryContext, MainContext };
