import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const SelectInputValueId = ({
    name,
    options,
    inputName,
    inputSetter = null,
    value = null,
    makeEmpty,
    handleMakeEmpty,
}) => {
    const { t } = useTranslation()
    //toggle select
    const [isOpen, setIsOpen] = useState(false);
    //current name of current selected value
    const [selectValue, setSelectValue] = useState(t("choose"));

    //select option handler
    const handleSelect = (index) => {
        setSelectValue(options[index].name);
        if (inputSetter) {
            inputSetter(options[index].value, inputName);
        }
    }

    //toggle select handler
    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (value) {
            options.forEach(element => {
                if (element.value === value) {
                    setSelectValue(element.name);
                }
            });
        } else {
            setSelectValue(t("choose"));
        }
    }, [value])

    useEffect(() => {
        if (makeEmpty) {
            setSelectValue(t("choose"));
            handleMakeEmpty(false);
        }
    }, [makeEmpty])

    return (
        <div>
            <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
            <div
                onClick={() => { handleOpen() }}
                className="border border-slate-300 w-full p-2 rounded-lg focus:outline-slate-300
                 dark:bg-slate-900 dark:text-gray-300 relative
                 dark:border-slate-600 dark:focus:outline-slate-600 dark:hover:border-slate-600 bg-white">
                <div
                    className="h-[22px] flex justify-between items-center ">
                    <p className="text-sm text-gray-500">
                        {selectValue}
                    </p>
                    {isOpen ?
                        (<ChevronUp className="text-sm text-gray-500" />) :
                        (<ChevronDown className="text-sm text-gray-500" />)
                    }
                </div>
                {isOpen ?
                    (<div className="w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                        absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                        dark:bg-slate-800  dark:border-slate-600">
                        {
                            options.map((obj, index) => {
                                return (
                                    <div
                                        onClick={() => { handleSelect(index); }}
                                        key={obj.name} className="p-3 w-full hover:bg-slate-300 group" >
                                        <p className="text-sm text-gray-500 font-bold dark:text-gray-300
                                            group-hover:text-slate-800">{obj.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>) :
                    (<></>)
                }

            </div>
        </div>
    )
}


export default SelectInputValueId;