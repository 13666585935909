import { useEffect, useState } from "react";
import QuestionMark from "../QuestionMark";
import CheckBox from "../CheckBox";
import RadioButton from "../RadioButton";
import SelectComponent from "../selectFolder/SelectComponent";
import CounterOne from "../counterFolder/CounterOne";
import CounterTwo from "../counterFolder/CounterTwo";
import CounterThree from "../counterFolder/CounterThree";
import CounterFour from "../counterFolder/CounterFour";
import DateInputOne from "../dateInputFolder/DateInputOne";
import DateInputTwo from "../dateInputFolder/DateInputTwo";
import TimeInput from "../TimeInput";
import TextArea from "../TextArea";
import FileUpload from "../FileUpload";
import JustText from "../JustText";
import { BiChevronDown } from "react-icons/bi";
import OtherDropdown from "./OtherDropdown";
import JustCounter from "../counterFolder/JustCounter";
import NextCounterFour from "../counterFolder/NextCounterFour";
import NextCounterJustOne from "../counterFolder/NextCounterJustOne";
import NextCounterOne from "../counterFolder/NextCounterOne";
import { ContactField } from "../ContactField";
import Linkk from "../simpleLinks/Link";
import YoutubeLink from "../simpleLinks/YoutubeLink";
import TextWithoutColor from "../TextWithoutColor";

const DropDown = ({ item, id }) => {
  const [flag, setFlag] = useState(false);
  const [serviceRows, setServiceRows] = useState([]);
  const [otherDropdownData, setOtherDropdownData] = useState([]);

  useEffect(() => {
    setServiceRows([]);
    setOtherDropdownData([]);
    if (item?.service_rows?.length !== 0) {
      var tmp = false;
      var wholeList = {};
      var listOfLists = [];
      var startIndex = "";

      Object.values(item?.service_rows).map((item) => {
        if (tmp && item?.type !== "16") {
          listOfLists.push(item);
        } else if (item?.type === "15") {
          tmp = true;
          startIndex = item.id;
          setServiceRows((prev) => [...prev, item]);
        } else if (item?.type === "16") {
          wholeList = {
            ...wholeList,
            [startIndex]: listOfLists,
          };
          listOfLists = [];
          startIndex = "";
          tmp = false;
        } else if (item?.type !== "16" && !tmp) {
          setServiceRows((prev) => [...prev, item]);
        }
      });
      setOtherDropdownData(wholeList);
    }
  }, [item]);

  return (
    <div
      className={`rounded-xl ${item?.service_rows?.length !== 0 && "bg-white"}`}
    >
      <div
        className={`w-full cursor-pointer flex items-center justify-between 
        ${flag && "rounded-b-none"} 
        ${
          item?.service_rows?.length === 0
            ? "text-[#0d84ff]"
            : "p-4  border border-[#0d84ff] rounded-xl"
        }`}
        onClick={() => {
          item?.service_rows?.length !== 0 && setFlag((prev) => !prev);
        }}
      >
        <p
          className={`${item?.service_rows?.length === 0 && "text-[#0d84ff]"}`}
        >
          {item?.name}
        </p>
        <div className="flex items-center gap-2">
          {(item?.img || item?.description) && (
            <QuestionMark
              imgLink={item?.img}
              redactorText={item?.description}
            />
          )}
          {item?.service_rows?.length !== 0 && (
            <div className="w-8 h-8 rounded-full bg-[#0d84ff]">
              <BiChevronDown
                className={`w-full h-full text-white duration-300 ${
                  flag && "rotate-180"
                }`}
              />
            </div>
          )}
        </div>
      </div>
      {item?.service_rows?.length !== 0 && flag && (
        <div className={`transition-all`}>
          <div className="p-4 rounded-lg rounded-t-none w-full">
            {serviceRows.map((serviceRow, index) => {
              return (
                <div key={serviceRow.id} className="w-full">
                  {serviceRow.type === "1" && (
                    <CheckBox
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "2" && (
                    <RadioButton
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      optionsId={id}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "3" && (
                    <SelectComponent
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "4" && (
                    <CounterOne
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "5" && (
                    <CounterTwo
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "6" && (
                    <CounterThree
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "7" && (
                    <CounterFour
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "8" && (
                    <DateInputOne
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "9" && (
                    <DateInputTwo
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "10" && (
                    <TimeInput
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}

                  {serviceRow.type === "11" && (
                    <FileUpload
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                    />
                  )}
                  {serviceRow.type === "13" && (
                    <TextArea
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "14" && <JustText item={serviceRow} />}
                  {serviceRow.type === "15" && (
                    <OtherDropdown
                      item={serviceRow}
                      data={otherDropdownData[serviceRow.id]}
                      parentName={item.name}
                      id={index}
                    />
                  )}
                  {serviceRow.type === "17" && (
                    <JustCounter
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "18" && (
                    <NextCounterJustOne
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "19" && (
                    <NextCounterFour
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "20" && (
                    <NextCounterOne
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                    />
                  )}
                  {serviceRow.type === "21" && (
                    <ContactField item={serviceRow} />
                  )}
                  {serviceRow.type === "22" && <Linkk item={serviceRow} />}
                  {serviceRow.type === "23" && (
                    <YoutubeLink item={serviceRow} />
                  )}
                  {serviceRow.type === "24" && (
                    <TextWithoutColor item={serviceRow} />
                  )}
                  {serviceRow.type === "25" && (
                    <CheckBox
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      parentName={item.name}
                      needPriceInText={true}
                    />
                  )}
                  {serviceRow.type === "26" && (
                    <RadioButton
                      item={serviceRow}
                      parentId={id}
                      childId={index}
                      optionsId={id}
                      parentName={item.name}
                      needPriceInText={true}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
