import { useState, useEffect, useContext } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const RadioButton = ({
  item,
  optionsId,
  parentId,
  childId,
  parentName,
  needPriceInText,
}) => {
  const {
    handleAddChosedData,
    chosedData,
    handleUpdateChosedData,
    selectedCountries,
  } = useContext(PrimaryContext);

  const handleInputState = (e) => {
    if (e.target.checked) {
      if (
        chosedData.filter((data) => {
          return data.id === `${parentId}` && data;
        }).length === 0
      ) {
        handleAddChosedData({
          id: `${parentId}`,
          name: item?.name,
          price: item?.price,
          compareId: `${parentId}${childId}`,
          parentName: parentName,
        });
      } else {
        handleUpdateChosedData(`${parentId}`, {
          id: `${parentId}`,
          name: item?.name,
          price: item?.price,
          compareId: `${parentId}${childId}`,
          parentName: parentName,
        });
      }
    }
  };

  return (
    <div className="flex w-full h-auto justify-between flex-col gap-2 border-b border-slate-300 pb-3 mb-3">
      <div className="flex justify-between">
        <div className="flex gap-2 max-sm:items-center">
          <input
            type="radio"
            onChange={(e) => {
              handleInputState(e);
            }}
            name={optionsId}
            className="max-sm:w-4 max-sm:h-4 shrink-0"
          />
          <p className="mt-1">
            {item?.name}{" "}
            {needPriceInText && `${item?.price} ${selectedCountries.currency}`}
          </p>
        </div>
        {(item?.img || item?.description) && (
          <QuestionMark imgLink={item?.img} redactorText={item?.description} />
        )}
      </div>
    </div>
  );
};

export default RadioButton;
