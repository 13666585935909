import Select from "./Select";
import QuestionMark from "../QuestionMark";
import { useEffect, useState, useContext } from "react";
import { PrimaryContext } from "../../MainContext/MainContext";

const SelectComponent = ({ item, parentId, childId, parentName }) => {
  const { handleAddChosedData, handleDeleteChosedData, chosedData } =
    useContext(PrimaryContext);
  const [name, setName] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (selectedOption.price !== undefined) {
      // handleDeleteChosedData(`${parentId}${childId}`);
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: selectedOption.option,
        price: selectedOption.price,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [selectedOption]);

  useEffect(() => {
    const opts = item?.name.split(",");
    setName(opts[0]);
    if (item?.price !== "" && item?.price.includes(",")) {
      const prices = item?.price.split(",");
      setOptions(
        prices.map((item, index) => {
          return { option: opts[index + 1], price: item };
        })
      );
    } else {
      const tmp = opts.map((item) => {
        return { option: item, price: "1" };
      });
      setOptions(tmp.slice(1));
    }
  }, [item]);

  return (
    <div className="flex items-center w-full pb-3 mb-3 justify-between border-b border-slate-300 ">
      <div className="grid grid-cols-2 gap-2 w-full">
        <div className="w-full flex items-center">
          <p>{name}</p>
        </div>
        <Select
          inputSetter={setSelectedOption}
          options={options}
          selectedOption={selectedOption}
        />
      </div>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default SelectComponent;
