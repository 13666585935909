import Router from "./components/router/Router";
import { MainContext } from "./components/MainContext/MainContext";

function App() {
  return (
    <div className="App main-bg" >
      <MainContext>
      <Router />
      </MainContext>
    </div>
  );
}

export default App;
