import HomeHead from "../components/homeComponents/homeHead/HomeHead";
import HomeCountry from "../components/homeComponents/homeCountrys/HomeCountrys";
import HomeServices from "../components/homeComponents/homeServices/HomeServices";
import AboutUs from "../components/homeComponents/aboutUs/AboutUs";
import ContactSection from "../components/homeComponents/contactUs/ContactSection";

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center p-11 max-sm:p-5 gap-11">
      <HomeHead />
      <HomeCountry />
      <HomeServices />
      <AboutUs />
    </div>
  );
};

export default Home;
