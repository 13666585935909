import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import logo from "../../assets/img/logo.png";
import Authentication from "../../pages/authentication";
import {
  BsCart,
  BsFile,
  BsHouse,
  BsList,
  BsPerson,
  BsTextLeft,
  BsX,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FaHome } from "react-icons/fa";

const Header = () => {
  //translation
  const { t } = useTranslation();
  //login register popup
  const [popupLogin, setPopupLogin] = useState(false);
  //menu data
  const MenuData = [
    {
      name: t("home"),
      link: "/",
      showOn: "every",
      Icon: BsHouse,
    },
    {
      name: t("services"),
      link: null,
      id: "#services",
      showOn: "/",
      Icon: BsCart,
    },
    {
      name: t("aboutUs"),
      link: null,
      id: "#aboutUs",
      showOn: "/",
      Icon: BsTextLeft,
    },
    {
      name: t("myCabinet"),
      link: "orders",
      showOn: "every",
      Icon: BsPerson,
    },
  ];
  //show phone menu
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  return (
    <header className="bg-white flex items-center justify-center">
      <div className="w-full max-w-[1920px] flex items-center justify-between px-12 py-4 max-sm:p-5">
        <div className="flex items-center gap-4">
          <Link to={"/"}>
            <img src={logo} alt="#" className="w-[120px] max-sm:w-[100px]" />
          </Link>
          <div className="flex items-center gap-2 max-md:hidden">
            {MenuData.map((item) => {
              return (
                <div
                  key={item.name}
                  className={`font-bold capitalize ${
                    item.showOn !== "every" &&
                    item.showOn !== location.pathname &&
                    "hidden"
                  }`}
                >
                  {item.link ? (
                    <Link to={item.link}>{item.name}</Link>
                  ) : (
                    <a href={item.id}>{item.name}</a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center gap-3">
          <LanguageSwitcher />
        </div>

        <div
          className="md:hidden fixed bottom-20 z-50 left-[50%] translate-x-[-50%] bg-white border-2
          border-[#0d84ff] rounded-[30px] p-2 flex items-center gap-4"
        >
          <FaHome className={`text-2xl text-[#0d84ff]`} />
          {!showMenu ? (
            <BsList
              className="text-2xl text-[#0d84ff]"
              onClick={() => setShowMenu(true)}
            />
          ) : (
            <BsX
              className="text-2xl text-red-500"
              onClick={() => setShowMenu(false)}
            />
          )}
        </div>
        {showMenu && (
          <div
            className="md:hidden fixed main-bg
           top-0 left-0 w-screen h-screen z-40"
          >
            <div className="m-5  p-5 bg-white rounded-[20px]">
              {MenuData.map((item) => {
                return (
                  <div
                    key={item.name}
                    className={`capitalize ${
                      item.showOn !== "every" &&
                      item.showOn !== location.pathname &&
                      "hidden"
                    }`}
                  >
                    <div className="flex items-center gap-3 mt-3">
                      {item.link ? (
                        <>
                          <item.Icon className="text-[#0d84ff]" />

                          <Link
                            to={item.link}
                            onClick={() => setShowMenu(false)}
                          >
                            {item.name}
                          </Link>
                        </>
                      ) : (
                        <>
                          <item.Icon className="text-[#0d84ff]" />
                          <a href={item.id} onClick={() => setShowMenu(false)}>
                            {item.name}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="flex items-center gap-3 mt-3">
                <BsFile className="text-[#0d84ff]" />
                <Link>{t("conditions")}</Link>
              </div>
            </div>
          </div>
        )}
      </div>
      {popupLogin && <Authentication popupSetter={setPopupLogin} />}
    </header>
  );
};

export default Header;
