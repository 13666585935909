import { useContext, useEffect, useState } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const CheckBox = ({ item, parentId, childId, parentName, needPriceInText }) => {
  const {
    handleAddChosedData,
    handleDeleteChosedData,
    chosedData,
    selectedCountries,
  } = useContext(PrimaryContext);
  const [inputState, setInputState] = useState(false);

  const handleInputState = (e) => {
    setInputState(e.target.checked);
  };

  useEffect(() => {
    if (inputState) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: item?.name,
        price: item?.price,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [inputState]);

  useEffect(() => {
    if (
      chosedData.filter((data) => {
        return data.id === `${parentId}${childId}`;
      }).length === 0
    ) {
      setInputState(false);
    }
  }, [chosedData]);

  return (
    <div className="flex w-full h-auto justify-between flex-col gap-2 border-b border-slate-300 pb-3 mb-3">
      <div className="flex justify-between">
        <div className="flex gap-2 max-sm:items-center">
          <input
            type="checkbox"
            onChange={(e) => {
              handleInputState(e);
            }}
            checked={inputState}
            className="max-sm:w-4 max-sm:h-4 shrink-0"
          />
          <p className="mt-1">
            {item?.name}{" "}
            {needPriceInText && `${item?.price} ${selectedCountries.currency}`}
          </p>
        </div>
        {(item?.img || item?.description) && (
          <QuestionMark imgLink={item?.img} redactorText={item?.description} />
        )}
      </div>
    </div>
  );
};

export default CheckBox;
