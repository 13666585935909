import { Link } from "react-router-dom";
import { FaWhatsapp, FaViber, FaTelegramPlane } from "react-icons/fa";
import { useEffect, useState } from "react";

export const ContactField = ({ item }) => {
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    setNumbers(item?.price.split(","));
  }, [item]);

  return (
    <div className="my-2 flex gap-5 flex-col">
      {numbers?.length > 1 && <p className="mt-1">{item?.name}</p>}
      {numbers?.length === 1 && (
        <div className="flex items-center gap-2">
          <p>{item.name}</p>
          {numbers[0] && (
            <Link
              to={`tel:+${numbers[0]}`}
              className="text-[#3585ff] bold flex items-center gap-2 underline"
            >
              {numbers[0]}
            </Link>
          )}
        </div>
      )}
      {numbers?.length > 1 && (
        <div className="flex items-center gap-5 max-lg:flex-col max-sm:grid max-sm:grid-cols-2 max-sm:gap-3">
          {numbers?.length === 2 && (
            <>
              {numbers[1] && (
                <Link
                  to={`https://wa.me/${numbers[1]}`}
                  target="_blank"
                  className="px-6 py-2 bg-[#42a20f] rounded-2xl text-white bold flex items-center justify-center gap-2 min-w-[150px]"
                >
                  <FaWhatsapp className="text-2xl" />
                </Link>
              )}
            </>
          )}
          {numbers?.length === 3 && (
            <>
              {numbers[2] && (
                <Link
                  to={`viber://chat?number=${numbers[2]}`}
                  className="px-6 py-2 bg-[#754e9a] rounded-2xl text-white bold flex items-center justify-center gap-2 min-w-[150px]"
                >
                  <FaViber className="text-2xl" />
                </Link>
              )}
            </>
          )}
          {numbers?.length === 4 && (
            <>
              {numbers[3] && (
                <Link
                  to={`https://t.me/${numbers[3]}`}
                  className="px-6 py-2 bg-[#0d84ff] rounded-2xl text-white bold flex items-center justify-center gap-2 min-w-[150px]"
                >
                  <FaTelegramPlane className="text-2xl" />
                </Link>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
