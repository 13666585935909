import { useState, useEffect, useContext, useRef } from "react";
import axiosClient from "../../api/api";
import DropDown from "../input/dropdown/DropDown";
import { PrimaryContext } from "../MainContext/MainContext";
import useScreenSize from "../customHooks/UseScreenSize";
import OrderPopup from "../../pages/OrderPopup";
import QuestionMark from "../input/QuestionMark";
import { useTranslation } from "react-i18next";
import {
  BsCart3,
  BsFullscreen,
  BsFullscreenExit,
  BsShare,
} from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";
import ReactToPrint from "react-to-print";
import logo from "../../assets/img/logo.png";

const ChooseOptions = ({ optionsId }) => {
  const { t } = useTranslation();
  const { chosedData, handleDeleteChosedData, selectedCountries } =
    useContext(PrimaryContext);

  const [questionsData, setQuestionsData] = useState(null);

  const [questions, setQuestions] = useState([]);
  const screenSize = useScreenSize();
  const [chosedPopup, setChosedPopup] = useState(false);
  const [showBiggerChoose, setShowBiggerChoose] = useState(false);
  const handleShowBiggerChoose = () => {
    setShowBiggerChoose((pre) => !pre);
  };
  const [totalCost, setTotalCost] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pdfRef = useRef();
  const [finishOrderToggle, setFinishOrderToggle] = useState(false);

  useEffect(() => {
    if (optionsId !== null) {
      setIsLoading(true);
      axiosClient
        .get(`/service/${optionsId}`)
        .then(({ data }) => {
          setQuestionsData(data.data);

          setQuestions(data.data.service_tables);

          setIsLoading(false);
        })
        .catch((err) => {});
    }
  }, [optionsId]);

  const handleShowChose = () => {
    if (screenSize < 1024) {
      setChosedPopup((prev) => !prev);
    } else {
      setChosedPopup(false);
    }
  };

  useEffect(() => {
    setTotalCost(0);
    var percents = [];
    chosedData.map((item) => {
      if (item.price?.length !== 0) {
        if (item.price?.toString()?.includes("%")) {
          percents.push(item.price);
        } else {
          if (!isNaN(parseInt(item.price)) && !(item.dontAdd === true)) {
            return setTotalCost((prev) => prev + parseInt(item.price));
          } else {
            return setTotalCost((prev) => prev + 0);
          }
        }
      }
    });

    if (percents.length !== 0) {
      percents.forEach((percent) => {
        setTotalCost(
          (prev) => prev + (prev * parseInt(percent.replace("%", ""))) / 100
        );
      });
    }
  }, [chosedData]);

  return (
    <section className="min-h-screen w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="w-[70%] max-lg:w-full gap-3 flex flex-col max-lg:min-h-screen">
          {questionsData !== null && (
            <div className={`w-full flex items-center justify-between`}>
              <p className="text-[#0d84ff]">
                {questionsData !== null && questionsData.name}
              </p>
              {questionsData.description !== null && (
                <QuestionMark redactorText={questionsData} />
              )}
            </div>
          )}
          {isLoading ? (
            <div
              className={`w-full flex items-center justify-center ${
                isLoading && "min-h-[200px]"
              }`}
            >
              <BiLoaderAlt className="w-20 h-20 animate-spin text-[#0d84ff]" />
            </div>
          ) : (
            questions.map((item, index) => {
              return <DropDown key={item.id} item={item} id={index} />;
            })
          )}
        </div>
        <div
          className={`z-50 duration-300 ease-linear
            ${
              screenSize < 1024
                ? "fixed bottom-0 left-0 w-full"
                : showBiggerChoose
                ? "fixed bottom-0 right-0 w-full h-full"
                : "relative w-[30%]"
            }
          `}
        >
          <div
            className={`bg-white shadow-my border border-slate-300 max-lg:pb-16
            rounded-[30px] w-full flex flex-col sticky top-10 left-0  max-lg:transition-all max-lg:duration-300 max-lg:ease-linear
          ${
            screenSize < 1024
              ? chosedPopup
                ? showBiggerChoose
                  ? "h-[100vh]"
                  : "h-[50vh] "
                : "h-0"
              : showBiggerChoose
              ? "w-full h-full"
              : "h-[60vh]"
          }
          `}
          >
            <div className="flex items-center justify-between w-full border-b border-gray-300 overflow-hidden p-5">
              <div className="flex items-center justify-between gap-[20px] w-full">
                <button
                  className="bg-[#3585ff] text-white text-sm font-bold px-4 py-2 rounded-full "
                  onClick={() => {
                    setFinishOrderToggle(true);
                  }}
                >
                  {t("sendOrder")}
                </button>
                <div className="flex items-center gap-2">
                  <ReactToPrint
                    trigger={() => (
                      <div
                        className={`duration-200 cursor-pointer h-[38px] w-[38px] flex 
                    items-center justify-center rounded-full bg-[#3585ff] text-white`}
                      >
                        <BsShare />
                      </div>
                    )}
                    content={() => {
                      // Create a wrapper div to hold both original and additional content
                      const wrapperDiv = document.createElement("div");

                      wrapperDiv.classList.add("m-5");

                      // Create additional HTML content
                      const additionalContentStart =
                        document.createElement("div");
                      additionalContentStart.innerHTML = `<div class="flex items-center gap-2"><img class="w-[120px] p-2" src="${logo}"></img>
                      <p class="text-[#0d84ff]">${questionsData.name}</p></div>`;

                      // Create additional HTML content
                      const additionalContentEnd =
                        document.createElement("div");
                      additionalContentEnd.innerHTML = `<div class="w-full mt-5 flex items-center justify-end p-2" ><p>
                        ${t("cost")}: ${parseInt(totalCost).toFixed(2)} ${
                        selectedCountries.currency
                      }
                        </p></div>`;

                      // Append the additional content to the wrapper
                      wrapperDiv.appendChild(additionalContentStart);

                      // Clone the original content into the wrapper
                      wrapperDiv.appendChild(pdfRef.current.cloneNode(true));

                      // Append the additional content to the wrapper
                      wrapperDiv.appendChild(additionalContentEnd);

                      // Return the modified content
                      return wrapperDiv;
                    }}
                  ></ReactToPrint>
                  <div
                    onClick={() => handleShowBiggerChoose()}
                    className={`duration-200 cursor-pointer h-[38px] w-[38px] flex 
                    items-center justify-center rounded-full bg-[#3585ff] text-white`}
                  >
                    {showBiggerChoose ? <BsFullscreenExit /> : <BsFullscreen />}
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={pdfRef}
              className={`flex flex-col gap-2 h-[78%] max-lg:h-[82%] p-2 overflow-auto ${
                screenSize < 1024 && (chosedPopup ? "visible" : "hidden")
              }`}
            >
              {chosedData.map((item) => {
                return (
                  <div
                    className="flex flex-col gap-1 w-full border-b border-gray-300"
                    key={item.id}
                  >
                    <p className="text-green-400 text-sm font-bold">
                      {item.parentName}
                    </p>
                    <div className="flex flex-col items-center justify-between w-full">
                      <p className="text-slate-500 text-md font-bold w-full">
                        {item.name}
                      </p>
                      <div className="flex items-center justify-between gap-2 w-full">
                        <p className="text-slate-500 text-md font-bold">
                          {item.price !== "0" &&
                            (item.priceString !== undefined
                              ? item.priceString
                              : item.price)}
                        </p>
                        <p
                          className="text-red-500 text-md font-bold cursor-pointer"
                          onClick={() => {
                            handleDeleteChosedData(item.id);
                          }}
                        >
                          X
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="flex justify-between items-center bg-[#0d84ff] rounded-[30px] value-container max-lg:rounded-b-none max-lg:p-4
              max-lg:absolute max-lg:bottom-0 max-lg:left-0 max-lg:w-screen max-lg:cursor-pointer h-[8%] max-lg:h-16"
              onClick={handleShowChose}
            >
              <div className="p-[10px] text-white flex items-center gap-2">
                <BsCart3  className="shrink-0 text-2xl" />
                <p className="shrink-0">{t("cost")}</p>
              </div>
              <div className="bg-white shadow-[0px_0px_2px_rgba(0,0,0,0.25)] rounded-[30px] p-[9px] text-green-500">
                <p>
                  {parseInt(totalCost).toFixed(2)} {selectedCountries.currency}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {finishOrderToggle && (
        <OrderPopup popupSetter={setFinishOrderToggle} serviceId={optionsId} />
      )}
    </section>
  );
};

export default ChooseOptions;
