import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({
    inputSetter,
    value = null,
    name,
}) => {
    const [editorState, setEditorState] = useState('');

    const handleEditorChange = (html) => {
        if (html === "<p><br></p>") {
            setEditorState('');
        } else {
            setEditorState(html);
        }
    };

    useEffect(() => {
        if (inputSetter) {
            inputSetter(editorState)
        }
    }, [editorState])

    useEffect(() => {
        if (value) {
            setEditorState(value)
        }
    }, [value])

    const modules = {
        toolbar: [
            [
                { 'size': ['small', false, 'large', 'huge'] },
                { 'header': [1, 2, 3, 4, 5, 6, false] },
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { 'list': 'ordered' },
                { 'list': 'bullet' },
                { 'script': 'sub' },
                { 'script': 'super' },
                { 'color': [] },
                { 'background': [] },
                { 'indent': '-1' },
                { 'indent': '+1' },
                { 'align': [] },
                'clean'
            ],
        ]
    };

    return (
        <>
            <ReactQuill
                value={editorState}
                onChange={handleEditorChange}
                modules={modules}
            />
            <textarea
                name={name}
                style={{ display: 'none' }}
                value={editorState}
                readOnly
            />
        </>
    );
};

export default TextEditor;
