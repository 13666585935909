import { useState, useEffect, useContext } from "react";
import QuestionMark from "../QuestionMark";
import DateInput from "./DateInput";
import { PrimaryContext } from "../../MainContext/MainContext";

const DateInputTwo = ({ item, parentId, childId, parentName }) => {
  const { handleAddChosedData, handleDeleteChosedData, chosedData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");
  const [inputState2, setInputState2] = useState("");

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  const handleInputState2 = (e) => {
    setInputState2(e.target.value);
  };

  useEffect(() => {
    if (inputState) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: `${item?.name} - დან-${inputState} მდე-${inputState2}`,
        price: item?.price,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [inputState, inputState2]);

  useEffect(() => {
    if (
      chosedData.filter((data) => {
        return data.id === `${parentId}${childId}`;
      }).length === 0
    ) {
      setInputState("");
      setInputState2("");
    }
  }, [chosedData]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 max-sm:gap-2 max-sm:items-start"
    >
      <div
        className="flex gap-2 items-center justify-between max-sm:flex-col w-full
      max-sm:justify-start max-sm:items-start
      "
      >
        <p className="mt-1">{item?.name}</p>
        <div className="flex items-center gap-2 max-sm:w-full">
          <DateInput
            inputValue={inputState}
            inputSetter={handleInputState}
            addStyle={"max-sm:w-full "}
          />
          <DateInput
            inputValue={inputState2}
            inputSetter={handleInputState2}
            addStyle={"max-sm:w-full"}
          />
        </div>
      </div>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default DateInputTwo;
