import QuestionMark from "./QuestionMark";

const TextWithoutColor = ({ item }) => {
  return (
    <div className="w-full flex items-center justify-between mb-3">
      <p className="font-bold text-sm">{item?.name}</p>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default TextWithoutColor;
