import { useState } from "react"
import { BsX, BsFillQuestionCircleFill } from "react-icons/bs";

const QuestionMark = ({
  imgLink = null,
  redactorText = null
}) => {
  const [popup, setPopup] = useState(false);

  return (
    <div className={`flex flex-col items-center`}>
      <div className="w-6 h-6"
        onClick={() => { setPopup(true) }}
      >
        <BsFillQuestionCircleFill className="text-slate-600 w-full h-full z-[1] relative" />
      </div>
      {popup &&
        <div className="w-screen h-screen bg-black bg-opacity-80 flex items-center justify-center z-[900]
        fixed top-0 left-0">
          <div className="w-[40%] max-lg:w-[80%] max-sm:w-[90%] h-[80vh] bg-white rounded-[30px] border border-slate-300 shadow-md p-10 relative
           px-5
          ">
            <BsX className="absolute text-4xl text-red-500 top-1 right-4 cursor-pointer" onClick={() => { setPopup(false) }} />
            <div className="overflow-auto w-full h-full px-5">
              {imgLink &&
                <img src={`${process.env.REACT_APP_API_URL}/storage/${imgLink}`} alt="#" className="w-full h-auto rounded-lg mt-2" />
              }
              {redactorText &&
                <div className="w-full"
                  dangerouslySetInnerHTML={{ __html: redactorText !== null && (typeof redactorText === "string" ? redactorText : redactorText.description) }}
                >

                </div>
              }
            </div>
          </div>
        </div>
      }

    </div>
  )
}

export default QuestionMark