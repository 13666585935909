const translationEn = {
  home: "Home",
  authorization: "Authorization",
  bannertext1: "All services",
  bannertext2: "in one space",
  chooseCountryText1: "Choose the country and city",
  chooseCountryText2: "you want and you will get full service",
  chooseLocation: "Choose a location",
  country: "Country",
  region: "Region",
  city: "City",
  choose: "Choose",
  our: "Our",
  services: "Services",
  registration: "Registration",
  name: "Name",
  surname: "Surname",
  number: "Phone Number",
  email: "Email",
  password: "Password",
  rePassword: "Repeat Password",
  doNotHaveAccount: "You don't have an account",
  haveAccount: "Have an account",
  passwordMailIncorrect: "The number or password is incorrect!",
  invalidData: "An error was detected. Please provide valid data!",
  youChosed: "You Chosen",
  sendOrder: "Sending of orde",
  cost: "Total cost",
  address: "Eexact address",
  noAdds: "We don't bother the user with different ads.",
  personalInfo: "I agree to the processing of personal data.",
  endOrder: "Order",
  depth: "Depth",
  parent: "Parent",
  withoutParent: "Without Parent",
  index: "Index",
  language: "Language",
  add: "Add",
  update: "Update",
  img: "Image",
  addNewAdmin: "Add new admin",
  save: "Save",
  updateAdmin: "Update admin profile",
  dropDown: "Deop Down",
  field: "Field",
  title: "Title",
  type: "Type",
  price: "Price",
  priceMap: "Price(Or Range-Price,)-kg,amount",
  check: "Check box",
  radio: "Radio",
  select: "Select",
  plus: "Counter range",
  plus2: "Counter 2",
  plus3: "Counter 3",
  plus4: "Counter 4",
  date: "Date",
  date2: "Date 2",
  time: "Time",
  imgUpload: "Image upload",
  textInput: "Text input",
  text: "Text",
  onlyText: "Only text",
  dropdownStart: "Start of deop Down",
  dropdownEnd: "End of deop Down",
  onlyPlus: "Only Counter",
  addService: "Add sevice",
  serviceQueston: "Service questions",
  countries: "Countries",
  admins: "Admins",
  next: "Next",
  want_clear: "Do you want me to remember the selected services ?",
  yes: "yes",
  no: " no",
  contact: "contact",
  search: "search",
  findService: "Find the desired service",
  seeMore: "see more",
  ab: "we",
  abuuss: "about",
  aboutUs: "about us",
  services: "services",
  conditions: "terms and conditions",
  allRights: "All terms reserved © 2024",
  abusTextDescr: `Using the services of our company, you can be sure that 
    you will receive qualified assistance and quality assurance in a timely 
    manner. Problems will be solved promptly and without loss of time. Our 
    team consists of highly qualified specialists focused on continuous 
    development and well-coordinated work.<br/>
    Our main goal is punctuality, transparency, and , what is most important :<br/>
    - we ensure the quality of all processes;<br/>
    - we value our partners and clients and are always open to cooperation;<br/>
    - we will consider proposals of our partners and customers for improvement of 
    functionality of the site, the opinion of our users is important to us.<br/><br/>
    How we are working<br/>
    1. Problem statement - You send us an application.<br/>
    2. Clarification of details - The manager goes into detail and asks clarifying questions.<br/>
    3. Manager's job - The manager entrusts the execution of the task to a specialist and controls the execution.<br/>
    4. Getting a result - We send you the result. We prepare a report by necessity.`,
  myCabinet: "My Cabinet",
  service: "Service",
  orderId: "order number",
  orderDate: "Order Date",
  status: "status",
  paymentStatus: "payment status",
  done: "შესრულებულია",
  isNotDone: "შეუსრულებულია",
  paid: "გადახდილია",
  notPaid: "გადაუხდელია",
  detailedInformation: "დეტალური ინფორმაცია",
  done: "done",
  isNotDone: "not done",
  paid: "paid",
  notPaid: "not paid",
  detailedInformation: "details",
  chooseCountriesWarning:
    "Please select a country/region/city before choosing a service",
  callFree: "The call is free",
  link: "link",
  ytLink: "YouTube Link",
  textWithoutColor: "text without color",
  additionalInfo: "Additional information",
  pleaseChooseCountry:
    "Before choosing the service, please select the desired city, region and country",
};
export default translationEn;
