import React, { useState } from 'react';
import { useEffect } from 'react';

function ContinueComponent({ showFlag, actionFunction, id = null, showContinuemsg, setShowContinue }) {
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Perform the desired action when the user confirms
    const handleContinue = () => {
        if (id || id === 0) {
            actionFunction(id);
        } else {
            actionFunction();
        }
        setShowConfirmation(false);
        setShowContinue(false);
    };

    // Handle cancellation or closing of the dialog
    const handleCancel = () => {
        setShowContinue(false);
        setShowConfirmation(false);
    };

    //show continue
    useEffect(() => {
        setShowConfirmation(showFlag);
    }, [showFlag])

    return (
        <>
            {showConfirmation && (
                <div className="fixed top-2 left-[50%] translate-x-[-50%] p-4 border border-gray-300 shadow-sm
                rounded-lg z-[90] bg-white">
                    <p className='text-gray-400 text-sm mt-2'>
                        {showContinuemsg ? showContinuemsg : "Are you sure you want to continue?"}
                    </p>
                    <div className='flex items-center justify-between'>
                        <button
                            onClick={handleContinue}
                            className='py-2 px-4 rounded-lg mt-2 bg-green-400 text-white
                            font-bold'
                        >
                            Ok
                        </button>
                        <button
                            onClick={handleCancel}
                            className='py-2 px-4 rounded-lg mt-2 bg-red-400 text-white
                            font-bold'
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContinueComponent;