import { useEffect, useState } from "react";
import axiosClient from "../api/api";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import OrdersCard from "./OrdersCard";

const Orders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dummyArray = Array.from({ length: 4 });
  const [searchKey, setSearchKey] = useState("");

  const handleSearchKey = (e) => {
    const searchKey = e.target.value;
    setSearchKey(encodeURIComponent(searchKey));
  };

  useEffect(() => {
    setIsLoading(true);
    axiosClient.get(`/order?phone=${searchKey}`).then(({ data }) => {
      setOrders(data);
      setIsLoading(false);
    });
  }, [searchKey]);

  return (
    <div className="flex items-center justify-center">
      <div className="min-w-screen min-h-screen m-10 max-sm:m-5 max-w-[1920px] w-full">
        <div
          className="flex items-center justify-between max-sm:flex-col 
            max-sm:gap-4 max-sm:justify-start max-sm:items-start"
        >
          <h1 className="text-2xl max-sm:text-[18px] font-bold ">
            {t("myCabinet").split(" ")[0]}{" "}
            <span className="text-[#0d84ff]">
              {t("myCabinet").split(" ")[1]}
            </span>
          </h1>
          <div
            className="h-10 bg-white overflow-hidden rounded-lg border
            border-[#0d84ff] flex items-center px-4 max-sm:w-full"
          >
            <input
              type="search"
              className="h-full border-none outline-none w-full"
              placeholder={t("findService")}
              onChange={handleSearchKey}
            />
            <FaSearch className="text-[#0d84ff] shrink-0" />
          </div>
        </div>
        <div className="w-full grid grid-cols-1 gap-5 mt-5">
          {isLoading
            ? dummyArray.map((_, index) => {
                return (
                  <div
                    className="animate-pulse dark-gray-bg w-full h-20 rounded-xl"
                    key={index}
                  ></div>
                );
              })
            : orders.map((order) => {
                return <OrdersCard key={order.id} data={order} />;
              })}
        </div>
      </div>
    </div>
  );
};

export default Orders;
