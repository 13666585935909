import { useEffect, useState } from "react";

const BasicSelectComponent = ({
  name,
  options,
  inputName,
  defaultValue,
  retrieveValue,
}) => {
  const [onChangeValue, setOnchangeValue] = useState(0);

  const handleOnChangeValue = (e) => {
    setOnchangeValue(e.target.value);
    if (retrieveValue) {
      retrieveValue(e.target.value);
    }
  };

  useEffect(() => {
    if (defaultValue || defaultValue === 0) {
      setOnchangeValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="w-full">
      <p className="text-sm text-gray-500 pb-1">{name}</p>
      <select
        className="w-full h-10 rounded-lg border border-slate-300 outline-none text-sm text-gray-500"
        name={inputName}
        onChange={(e) => {
          handleOnChangeValue(e);
        }}
        value={onChangeValue && parseInt(onChangeValue)}
      >
        {options.map((item, index) => {
          return (
            <option
              key={index}
              value={item.value}
              className="text-sm text-gray-500"
            >
              {item.id !== undefined && `${item.id} - `}
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default BasicSelectComponent;
