import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const Select = ({ options, inputSetter, selectedOption }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  const handelClick = (item) => {
    setSelectedValue(item);
    inputSetter(item);
    setShow(false);
  };

  useEffect(() => {
    setOptionsData(options);
  }, [options]);

  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [selectedOption]);

  return (
    <div className="flex flex-col gap-2 w-full relative">
      <div
        className="w-full flex items-center justify-between bg-[#F5F5F5] rounded-3xl p-3 cursor-pointer"
        onClick={() => setShow(!show)}
      >
        <p className="text-sm">
          {selectedValue.option === undefined
            ? t("choose")
            : selectedValue.option}
        </p>
        <div className="w-">{show ? <BiChevronUp /> : <BiChevronDown />}</div>
      </div>
      {show ? (
        <div
          className="w-full h-48 overflow-auto rounded-2xl flex flex-col p-2
        absolute top-[105%] left-0 z-30 bg-[#F5F5F5]"
        >
          {optionsData.map((item, index) => (
            <p
              key={index}
              onClick={() => handelClick(item)}
              className="p-2 rounded-lg text-sm cursor-pointer"
            >
              {item.option}
            </p>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Select;
