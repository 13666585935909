import TextInput from "../../inputComponents/TextInput";
import TextArea from "../../inputComponents/TextArea";
import FileInput from "../../inputComponents/FileInput";
import { BsX } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import { useState } from "react";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import TextEditor from "../../inputComponents/TextEditor";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const QuestionCard = ({
  parentId,
  handleDeleteQuestion1,
  parentInnerId,
  handleAddNextQuestionCard,
}) => {
  const { t } = useTranslation();
  const [question, setQuestions] = useState([]);
  //show contnue
  const [showContinue, setShowContinue] = useState(false);
  const [showContinue1, setShowContinue1] = useState(false);
  //delete id
  const [deleteId1, setDeleteId1] = useState(null);

  //delete item from list
  const handleDeleteQuestion = (id) => {
    setQuestions((prev) =>
      prev.filter((item) => {
        return item.id !== id && item;
      })
    );
  };

  //add item in list
  const handleAddQuestion = (id) => {
    setQuestions((prev) => [
      ...prev.slice(0, id + 1),
      {
        id: Date.now(),
        questionText: "",
        questionImg: "",
        name: "",
        type: "",
        price: "",
      },
      ...prev.slice(id + 1),
    ]);
  };

  const handleItemStart = () => {
    setQuestions((prev) => [...prev, { id: Date.now() }]);
  };

  return (
    <div className="flex flex-col mt-5 rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
      <div className="mb-5">
        <TextEditor name={`?nw${parentId}`} />
      </div>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 border-b border-slate-300 mb-5 pb-5">
        <div className="mt-6">
          <FileInput inputName={`x?nw${parentId}`} name={t("img")} />
        </div>
        <div className="flex gap-2">
          <div className="w-[85%]">
            <TextInput
              name={t("title")}
              inputName={`ms${parentId}`}
              placeholder={t("title")}
            />
          </div>
          <div className="flex items-center justify-end w-[15%] gap-2">
            <div
              className="w-8 h-8 p-2 bg-green-400 rounded-full mt-6 cursor-pointer"
              onClick={() => {
                handleAddNextQuestionCard(parentInnerId);
              }}
            >
              <BiPlus className="text-white h-full w-full" />
            </div>
            <div
              className="w-8 h-8 p-2 bg-red-400 rounded-full mt-6 cursor-pointer"
              onClick={() => {
                setShowContinue(true);
              }}
            >
              <BsX className="text-white h-full w-full" />
            </div>
          </div>
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteQuestion1}
        setShowContinue={setShowContinue}
        id={parentInnerId}
      />
      <div className="w-full">
        {question.map((item, index) => {
          return (
            <div className="w-full flex flex-col gap-2" key={item.id}>
              <div className="flex items-center justify-start gap-2">
                <div>
                  <p className="text-slate-500 text-2xl font-bold">
                    {index + 1}
                  </p>
                </div>
                <div
                  className="w-8 h-8 p-2 bg-green-400 rounded-full cursor-pointer"
                  onClick={() => {
                    handleAddQuestion(index);
                  }}
                >
                  <BiPlus className="text-white h-full w-full" />
                </div>
                <div
                  className="w-8 h-8 p-2 bg-red-400 rounded-full cursor-pointer"
                  onClick={() => {
                    setDeleteId1(item.id);
                    setShowContinue1(true);
                  }}
                >
                  <BsX className="text-white h-full w-full" />
                </div>
              </div>
              <div className="mb-2">
                <TextEditor name={`?w${parentId}-${index}`} />
              </div>
              <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 border-b border-slate-300 mb-5 pb-5">
                <div className="mt-6">
                  <FileInput
                    inputName={`x?w${parentId}-${index}`}
                    name={t("img")}
                  />
                </div>
                <TextArea
                  name={t("name")}
                  inputName={`s${parentId}-${index}`}
                />
                <BasicSelectComponent
                  name={t("type")}
                  options={[
                    {
                      name: `${t("check")} ☑`,
                      value: 1,
                    },
                    {
                      name: `${t("radio")} 🔘`,
                      value: 2,
                    },
                    {
                      name: t("select"),
                      value: 3,
                    },
                    {
                      name: t("plus"),
                      value: 4,
                    },
                    {
                      name: t("plus2"),
                      value: 5,
                    },
                    {
                      name: t("plus3"),
                      value: 6,
                    },
                    {
                      name: t("plus4"),
                      value: 7,
                    },
                    {
                      name: t("date"),
                      value: 8,
                    },
                    {
                      name: t("date2"),
                      value: 9,
                    },
                    {
                      name: t("time"),
                      value: 10,
                    },
                    {
                      name: t("imgUpload"),
                      value: 11,
                    },
                    {
                      name: t("textInput"),
                      value: 13,
                    },
                    {
                      name: t("onlyText"),
                      value: 14,
                    },
                    {
                      name: t("dropdownStart"),
                      value: 15,
                    },
                    {
                      name: t("dropdownEnd"),
                      value: 16,
                    },
                    {
                      name: t("onlyPlus"),
                      value: 17,
                    },
                    {
                      name: `${t("onlyPlus")} ${t("next")}`,
                      value: 18,
                    },
                    {
                      name: `${t("plus4")} ${t("next")}`,
                      value: 19,
                    },
                    {
                      name: `${t("plus")} ${t("next")}`,
                      value: 20,
                    },
                    {
                      name: `${t("contact")}`,
                      value: 21,
                    },
                    {
                      name: `${t("link")}`,
                      value: 22,
                    },
                    {
                      name: `${t("ytLink")}`,
                      value: 23,
                    },
                    {
                      name: `${t("textWithoutColor")}`,
                      value: 24,
                    },
                    {
                      name: `${t("check")} ☑ ₾`,
                      value: 25,
                    },
                    {
                      name: `${t("radio")} 🔘 ₾`,
                      value: 26,
                    },
                  ]}
                  inputName={`t${parentId}-${index}`}
                />
                <TextInput
                  name={t("price")}
                  inputName={`R${parentId}-${index}`}
                  placeholder={t("priceMap")}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="rounded-lg p-3 text-white text-sm bg-green-400 self-start cursor-pointer"
        onClick={handleItemStart}
      >
        + {t("field")}
      </div>
      <ContinueComponent
        showFlag={showContinue1}
        actionFunction={handleDeleteQuestion}
        setShowContinue={setShowContinue1}
        id={deleteId1}
      />
    </div>
  );
};

export default QuestionCard;
