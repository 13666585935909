import { useEffect, useState, useContext } from "react";
import CounterPart from "./CounterPart";
import QuestionMark from "../QuestionMark";
import { PrimaryContext } from "../../MainContext/MainContext";
import { BsX } from "react-icons/bs";

const CounterOne = ({
  item,
  parentId,
  childId,
  parentName,
  needDelete,
  handleDelete,
  counters,
}) => {
  const [dataValue, setDataValue] = useState([
    {
      id: 1,
      name: "+2",
      value: "",
    },
  ]);

  const [price, setPrice] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    for (let i = 0; i < price.length; i++) {
      if (price[i].range > dataValue[0].value) {
        setFinalPrice(price[i].price);
        setCurrentPrice(price[i].price);
        break;
      }
    }
  }, [dataValue]);

  useEffect(() => {
    try {
      const tmp = item?.price?.split("-");
      const nmms = tmp[1].split(",");
      const pprcs = tmp[0].split("|");

      setPrice(
        pprcs.map((item) => {
          let value = item.split("*");
          return {
            range: value[0],
            price: parseFloat(value[1]),
          };
        })
      );
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: item.value,
            name: nmms[index],
          };
        })
      );
    } catch {
      setPrice(
        dataValue.map((item) => {
          return {
            range: "0",
            price: "0",
          };
        })
      );
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: "0",
            name: "",
          };
        })
      );
    }
  }, [item]);

  const { handleAddChosedData, handleDeleteChosedData, selectedCountries } =
    useContext(PrimaryContext);

  useEffect(() => {
    const isValid = !dataValue.some((item) => [0, ""].includes(item.value));
    if (isValid) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: item?.name,
        price: dataValue[0].value * parseFloat(finalPrice),
        priceString: `${dataValue[0].value} x ${parseFloat(finalPrice)} = ${
          dataValue[0].value * parseFloat(finalPrice)
        }`,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [dataValue]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 flex-col gap-2"
    >
      <div className="flex items-center gap-2 w-full justify-between">
        <p className="mt-1">{item?.name}</p>
        <div className="flex gap-2 items-center">
          {needDelete && counters && counters[0].id !== childId && (
            <BsX
              onClick={() => {
                handleDelete(childId);
              }}
              className="text-red-500 text-lg cursor-pointer"
            />
          )}
          {(item?.img || item?.description) && (
            <QuestionMark
              imgLink={item?.img}
              redactorText={item?.description}
            />
          )}
        </div>
      </div>
      <div
        className="flex gap-5 w-full 
      max-lg:gap-2 max-lg:grid max-lg:grid-cols-2"
      >
        <CounterPart dataValue={dataValue} setDataValue={setDataValue} />
        <div className="flex items-center rounded-3xl px-4 py-1 bg-[#F5F5F5] lg:min-w-[160px]">
          <p className="w-full text-center">
            {`${currentPrice} ${
              selectedCountries.currency ? selectedCountries.currency : ""
            }`}
          </p>
        </div>
        <div className="flex items-center rounded-3xl px-4 py-1 bg-[#F5F5F5] lg:min-w-[160px]">
          <p className="w-full text-center">
            {dataValue[0].value * parseFloat(finalPrice)}{" "}
            {`${selectedCountries.currency ? selectedCountries.currency : ""}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CounterOne;
