import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="bg-white flex items-center justify-center">
      <div className="w-full flex items-center justify-between px-10 py-5 max-sm:px-5
      max-lg:flex-col gap-5 max-w-[1920px]">
        <img src={logo} alt="#" className="w-[120px]" />
        <div>
          <div className="flex items-center gap-4 border-b border-slate-300 mb-2 pb-2 max-sm:flex-col capitalize">
            <Link to={"/"}>{t("home")}</Link>
            <a href="#aboutUs">{t("aboutUs")}</a>
            <a href="#services">{t("services")}</a>
            <Link>{t("conditions")}</Link>
          </div>
          <p className="text-center text-gray-500">{t("allRights")}</p>
        </div>
        <div className="flex gap-2">
          <div className="w-8 h-8 p-1 cursor-pointer">
            <FaFacebook className="text-[#0d84ff] w-full h-full font-bold" />
          </div>
          <div className="w-8 h-8 p-1 cursor-pointer">
            <FaInstagram className="text-[#0d84ff] w-full h-full font-bold" />
          </div>
          <div className="w-8 h-8 p-1 cursor-pointer">
            <FaLinkedin className="text-[#0d84ff] w-full h-full font-bold" />
          </div>
          <div className="w-8 h-8 p-1 cursor-pointer">
            <FaTwitter className="text-[#0d84ff] w-full h-full font-bold" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
