import { useTranslation } from "react-i18next";
import bgImg from "../../../assets/img/about-bg.png"

export default function AboutUs() {
    const { t } = useTranslation()

    return (
        <div div className='bg-white p-11 rounded-3xl max-sm:p-5 max-w-[1920px]' id="aboutUs">
            <h1 className="text-3xl max-sm:text-xl font-bold w-full text-center">
                <span className="text-[#0d84ff]">{t('ab')}{" "}</span>
                {t('abuuss')}
            </h1>
            <div className="flex mt-11 max-sm:mt-5 gap-5 max-lg:flex-col">
                <div className="w-7/12 flex flex-col gap-2 max-lg:order-2 max-lg:w-full">
                    <p className="indent-2 leading-[30px]" dangerouslySetInnerHTML={{ __html: t("abusTextDescr") }}>
                      
                    </p>
                </div>
                <div className="w-5/12 flex items-center justify-center max-lg:order-1 max-lg:w-full">
                    <img src={bgImg} alt="#" className="w-full rounded-3xl" />
                </div>
            </div>
        </div >
    )
}
