import TextInput from "../../inputComponents/TextInput";
import TextEditor from "../../inputComponents/TextEditor";
import InnerQuestionCard from "./InnerQuestionsCard";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import { useTranslation } from "react-i18next";
import { FaRegSave } from "react-icons/fa";

const InnerQuestions = () => {
  const { t } = useTranslation();
  const { questionsId } = useParams();
  const [updater, setUpdater] = useState(null);
  const [serviceObject, setServiceObject] = useState({});
  const [question, setQuestions] = useState([]);
  const [cities, setCities] = useState([]);
  const [lastCountries, setLastCountries] = useState([]);
  //selected city id for changing variation and creating variation copy
  const [vcrc, setVcrc] = useState(null);

  useEffect(() => {
    axiosAdmin
      .get(`/service/${questionsId}${vcrc !== null ? `?Vcrc_id=${vcrc}` : ""}`)
      .then(({ data }) => {
        setServiceObject(data.data);
        setQuestions(Object.values(data.data.service_tables));
      })
      .catch((err) => {
        if (
          err.response.data.message == `Attempt to read property "id" on null`
        ) {
          errorToast("ფასის ვარიაცია არაა შექმნილი");
        }
      });
  }, [updater, vcrc]);

  const handleDeleteQuestion = (id) => {
    if (question.filter((item) => item.id === id)[0].new) {
      setQuestions((prev) =>
        prev.filter((item) => {
          return item.id !== id && item;
        })
      );
    } else {
      axiosAdmin
        .delete(`/serviceTable/${id}`)
        .then(({ data }) => {
          setUpdater(Date.now());
        })
        .catch((err) => {});
    }
  };

  const handleAddQuestionCard = (id, addType) => {
    const newDiv = {
      id: Date.now(),
      headQuestionText: "",
      headQuestionImg: "",
      headName: "",
      service_rows: [],
      new: true,
      previousId: id,
    };

    switch (addType) {
      case "top":
        setQuestions((prev) => [newDiv, ...prev]);
        break;
      case "between":
        const updatedDivs = [...question];
        const index = updatedDivs.findIndex((div) => div.id === id);
        if (index !== -1) {
          updatedDivs.splice(index + 1, 0, newDiv);
          setQuestions(updatedDivs);
        }
        break;
      case "bottom":
        setQuestions((prev) => [...prev, newDiv]);
        break;
    }
  };

  useEffect(() => {
    axiosAdmin
      .get("/crc?type=city")
      .then(({ data }) => {
        setCities(
          data.map((item) => {
            return { name: item.name, value: item.id };
          })
        );
      })
      .catch((err) => {});

    axiosAdmin
      .get("/category?last=1")
      .then(({ data }) => {
        setLastCountries(
          data.map((item) => {
            return { name: item.name, value: item.id, id: item.sort };
          })
        );
      })
      .catch((err) => {});
  }, []);

  function handleSubmitQuestion(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    axiosAdmin
      .post(`/service/${questionsId}`, formData)
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  }

  const handleCopy = () => {
    axiosAdmin.get(`/copy/service/${questionsId}`).then((res) => {
      successToast("Success");
    });
  };

  const handleCopyAsVariation = () => {
    axiosAdmin.get(`/copy2/service/${questionsId}/${vcrc}`).then((res) => {
      successToast("Success");
    });
  };

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0
        min-h-screen"
    >
      <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5">
        <div className="bg-slate-100 flex flex-col gap-5">
          <div className="flex items-center gap-2">
            <div
              className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer self-start"
              onClick={handleCopy}
            >
              Copy
            </div>
            <div
              className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer self-start"
              onClick={handleCopyAsVariation}
            >
              Copy (ვარიაცია)
            </div>
            <div>
              <BasicSelectComponent
                options={cities}
                inputName={"crc_id"}
                defaultValue={0}
                retrieveValue={setVcrc}
              />
            </div>
          </div>
          <form
            onSubmit={(e) => {
              handleSubmitQuestion(e);
            }}
            encType="multipart/form-data"
            className="w-full"
          >
            <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
              <div>
                <button
                  type="submit"
                  className="w-8 h-8 p-2 bg-purple-400 rounded-full cursor-pointer shrink-0"
                >
                  <FaRegSave className="text-white h-full w-full" />
                </button>
              </div>
              <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 mt-4">
                <TextInput
                  name={t("name")}
                  inputName={"name"}
                  placeholder={t("name")}
                  value={serviceObject.name}
                />
                <BasicSelectComponent
                  name={t("parent")}
                  options={lastCountries}
                  inputName={"category_id"}
                  defaultValue={serviceObject.category_id}
                />
                <BasicSelectComponent
                  name={t("city")}
                  options={cities}
                  inputName={"crc_id"}
                  defaultValue={serviceObject.crc_id}
                />
                <TextInput
                  name={t("index")}
                  inputName={"sort"}
                  type="number"
                  value={serviceObject.sort}
                  placeholder={t("index")}
                />
                <TextInput
                  name={t("email")}
                  inputName={"mail"}
                  value={serviceObject.mail}
                  placeholder={t("email")}
                />
                <TextInput
                  name={t("language")}
                  inputName={"language"}
                  value={serviceObject.language}
                  placeholder={"GE,RU,EN"}
                />
              </div>
              <div className="mt-5">
                <TextEditor
                  name={"description"}
                  value={serviceObject.description}
                />
              </div>
            </div>
          </form>

          <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
            <div
              className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
              onClick={() => {
                handleAddQuestionCard(0, "top");
              }}
            >
              + {t("dropDown")}
            </div>
          </div>

          <div className="w-full">
            {question.map((item, index) => {
              return (
                <InnerQuestionCard
                  item={item}
                  key={item.id}
                  parentId={index}
                  parentInnerId={item.id}
                  handleDeleteQuestion1={handleDeleteQuestion}
                  handleAddQuestionCard={handleAddQuestionCard}
                  questionsId={questionsId}
                  dataUpdater={setUpdater}
                  vcrc={vcrc}
                  needCityWithPrice={true}
                  cities={cities}
                  setVcrc={setVcrc}
                />
              );
            })}
          </div>

          <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
            <div
              className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
              onClick={() => {
                handleAddQuestionCard(
                  question.length === 0 ? 0 : question[question.length - 1].id,
                  "bottom"
                );
              }}
            >
              + {t("dropDown")}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default InnerQuestions;
