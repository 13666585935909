import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const YoutubeLink = ({ item }) => {
  return (
    <div className="flex items-center gap-2  border-b border-slate-300 pb-3 mb-3">
      <p className="mt-1">{item?.name}</p>

      <Link to={item?.price} target="_blank">
        <FaYoutube className={"text-red-500 text-2xl"} />
      </Link>
    </div>
  );
};

export default YoutubeLink;
