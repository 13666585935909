import { useState, useEffect, useContext } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const TextArea = ({ item, parentId, childId, parentName }) => {
  const { handleAddChosedData, handleDeleteChosedData, chosedData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  useEffect(() => {
    if (inputState !== "") {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: inputState,
        price: 0,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [inputState]);

  useEffect(() => {
    if (
      chosedData.filter((data) => {
        return data.id === `${parentId}${childId}`;
      })?.length === 0
    ) {
      setInputState("");
    }
  }, [chosedData]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3"
    >
      <div className="flex flex-col gap-2 w-full">
        <p className="mt-1">{item?.name}</p>
        <textarea
          onChange={(e) => {
            handleInputState(e);
          }}
          value={inputState}
          placeholder="Text"
          className="outline-none pl-2
          h-14 bg-white flex items-center justify-between gap-2 rounded-2xl
          border-slate-300 border text-slate-500 w-full"
        />
      </div>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default TextArea;
