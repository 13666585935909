import { useState, useRef } from "react";
import { BsFileEarmarkArrowUp, BsCheckLg } from "react-icons/bs";

const FileInput = ({
    name,
    inputName
}) => {
    //file input element
    const inputElement = useRef(null);
    //is uploaded
    const [isUploaded, setIsUploaded] = useState(false);

    const handleChange = (event) => {
        if (event.target.files[0].name !== null) {
            setIsUploaded(true);
        } else {
            setIsUploaded(false);
        }
    }

    const handleOpenInput = () => {
        inputElement.current.click();
    }

    return (
        <div className="w-full">
            <div
                onClick={handleOpenInput}
                className="rounded border-dashed border-2 h-10 gap-2 bg-white
                     border-gray-300 flex items-center justify-between cursor-pointer" >
                <div className="flex items-center">
                    <BsFileEarmarkArrowUp className="w-8 h-8 text-gray-300" />
                    <p className="text-sm text-gray-500">{name}</p>
                </div>
                {isUploaded &&
                    <div>
                        <BsCheckLg className="w-8 h-8 text-green-500" />
                    </div>
                }
            </div>
            <input
                name={inputName}
                ref={inputElement}
                className="hidden" type="file" onChange={handleChange} />
        </div>
    )
}

export default FileInput;