import { useState, useEffect, useContext } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const TimeInput = ({ item, parentId, childId, parentName }) => {
  const { handleAddChosedData, handleDeleteChosedData, chosedData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  useEffect(() => {
    if (inputState) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: `${item?.name} - ${inputState}`,
        price: item?.price,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [inputState]);

  useEffect(() => {
    if (
      chosedData.filter((data) => {
        return data.id === `${parentId}${childId}`;
      }).length === 0
    ) {
      setInputState("");
    }
  }, [chosedData]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3"
    >
      <div className="flex items-center gap-2 w-full max-sm:flex-col">
        <div className="w-full flex items-center">
          <p className="mt-1">{item?.name}</p>
        </div>
        <input
          type="time"
          className="px-4 py-2 bg-[#F5F5F5] flex items-center justify-between gap-2 rounded-3xl
          outline-none cursor-pointer shrink-0 max-sm:w-full"
          onChange={(e) => {
            handleInputState(e);
          }}
          value={inputState}
        />
      </div>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default TimeInput;
