import { useState, useRef } from "react";
import { BsFileEarmarkArrowUp, BsCheckLg } from "react-icons/bs";
import QuestionMark from "./QuestionMark";
import { FaCamera } from "react-icons/fa";

const FileUpload = ({ item, name, inputName }) => {
  //file input element
  const inputElement = useRef(null);
  //is uploaded
  const [isUploaded, setIsUploaded] = useState(false);

  const handleChange = (event) => {
    if (event.target.files[0].name !== null) {
      setIsUploaded(true);
    } else {
      setIsUploaded(false);
    }
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  return (
    <div
      className="flex justify-between items-center w-full
        border-b border-slate-300 pb-3 mb-3"
    >
      <div
        className="flex gap-2 items-center justify-between w-full max-sm:flex-col
      max-sm:items-start"
      >
        <p className="mt-1">{item?.name}</p>
        <div className="flex items-center gap-2">
          <div
            onClick={handleOpenInput}
            className="rounded-3xl border-dashed border-2 h-10 gap-2 bg-white w-[200px] px-2
                     border-[#0d84ff] flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-center">
              <BsFileEarmarkArrowUp className="w-6 h-6 text-[#0d84ff]" />
              <p className="text-sm text-[#0d84ff]">{name}</p>
            </div>
            {isUploaded && (
              <div>
                <BsCheckLg className="w-8 h-8 text-green-500" />
              </div>
            )}
          </div>
        </div>
      </div>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
      <input
        name={inputName}
        ref={inputElement}
        className="hidden"
        type="file"
        onChange={handleChange}
      />
    </div>
  );
};

export default FileUpload;
