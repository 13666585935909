import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react";

const ClearNotification = ({ showFlag, actionFunction, declineFunction, id = null, setShowContinue }) => {
    const { t } = useTranslation();
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Perform the desired action when the user confirms
    const handleContinue = () => {
        if (id || id === 0) {
            actionFunction(id);
        } else {
            actionFunction();
        }
        setShowConfirmation(false);
        setShowContinue(false);
    };

    // Handle cancellation or closing of the dialog
    const handleCancel = () => {
        if (id || id === 0) {
            declineFunction(id);
        } else {
            declineFunction();
        }
        setShowContinue(false);
        setShowConfirmation(false);
    };

    //show continue
    useEffect(() => {
        setShowConfirmation(showFlag);
    }, [showFlag])

    return (
        <>
            {showConfirmation &&
                <div className="fixed top-5 left-[50%] translate-x-[-50%] bg-white z-50 p-5 rounded-[20px] border
            border-slate-300 max-sm:w-[80vw]">
                    <p className="text-base text-slate-500 font-medium">
                        {t("want_clear")}
                    </p>
                    <div className="flex items-center gap-2 mt-3">
                        <button
                            onClick={handleContinue}
                            className="w-full p-2 rounded-lg bg-[#3585ff] text-white">
                            {t("yes")}
                        </button>
                        <button
                            onClick={handleCancel}
                            className="w-full p-2 rounded-lg bg-red-500 text-white">
                            {t("no")}
                        </button>
                    </div>
                </div>
            }
        </>
    )
}

export default ClearNotification