import { useState, useEffect, useContext } from "react";
import QuestionMark from "../QuestionMark";
import DateInput from "./DateInput";
import { PrimaryContext } from "../../MainContext/MainContext";

const DateInputOne = ({ item, parentId, childId }) => {
  //get current date
  const today = new Date();

  const {
    handleAddChosedData,
    handleDeleteChosedData,
    chosedData,
    parentName,
  } = useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");
  const [dateRange, setDateRange] = useState({
    minDate: "",
  });

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  useEffect(() => {
    if (inputState) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: `${item?.name} - ${inputState}`,
        price: 0,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
      });
    } else {
      handleDeleteChosedData(item?.name);
    }
  }, [inputState]);

  useEffect(() => {
    if (
      chosedData.filter((data) => {
        return data.id === `${parentId}${childId}`;
      }).length === 0
    ) {
      setInputState("");
    }
  }, [chosedData]);

  useEffect(() => {
    if (item?.price !== "") {
      //generate date of today as following pattern YYYY-MM-DD
      const year = today.getFullYear();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + parseInt(item?.price));

      //generate date after specific days as following pattern YYYY-MM-DD
      const futureDateString = futureDate.toISOString().split("T")[0];

      setDateRange({
        minDate: futureDateString,
      });
    } else {
      setDateRange({
        minDate: today.toISOString().split("T")[0],
      });
    }
  }, [item]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 "
    >
      <div className="flex items-center gap-2 w-full max-sm:flex-col">
        <div className="w-full flex items-center">
          <p className="mt-1">{item?.name}</p>
        </div>
        <div className="max-sm:w-full">
          <DateInput
            inputValue={inputState}
            inputSetter={handleInputState}
            minValue={dateRange.minDate}
          />
        </div>
      </div>
      {(item?.img || item?.description) && (
        <QuestionMark imgLink={item?.img} redactorText={item?.description} />
      )}
    </div>
  );
};

export default DateInputOne;
