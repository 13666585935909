import { Routes, Route, useLocation } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Home from "../../pages/home";
import ServicePage from "../../pages/servicePage";
import AdminRouterLayout from "../../adminPanel/components/adminRouter/AdminRouterLayout";
import Orders from "../../pages/Orders";
import ScrollToTop from "../customHooks/ScrollToTop";
import { ToastContainer } from "react-toastify";

const Router = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname.split("/")[1] !== "admin" && <Header />}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:serviceId" element={<ServicePage />} />
        <Route path="admin/*" element={<AdminRouterLayout />} />
        <Route path="orders" element={<Orders />} />
      </Routes>
      {location.pathname.split("/")[1] !== "admin" && <Footer />}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Router;
