import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import FileInput from "../../inputComponents/FileInput";
import CountryDropper from "./CountryDropper";
import axiosAdmin from "../../../../api/apiAdmin";
import { useEffect, useState } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";

const AddCountries = () => {
    const { t } = useTranslation()
    const [countries, setCountries] = useState([]);
    const [justCountries, setJustCountries] = useState([]);
    const [justRegions, setJustRegions] = useState([]);
    const [updater, setUpdater] = useState(null);
    const [regionId, setRegionId] = useState({
        crc_id: 0
    })
    const [cityId, setCityId] = useState({
        crc_id: 0
    })

    const handleRegionCrc = (value, inputName) => {
        setRegionId({
            crc_id: value
        })
    }

    const handleCityCrc = (value, inputName) => {
        setCityId({
            crc_id: value
        })
    }

    useEffect(() => {
        axiosAdmin.get('/crc')
            .then(({ data }) => {
                setCountries(data);
            })
            .catch((err) => { })

        axiosAdmin.get('/crc?type=country')
            .then(({ data }) => {
                setJustCountries(data.map((item) => {
                    return { name: item.name, value: item.id }
                }))
            })
            .catch((err) => { })

        axiosAdmin.get('/crc?type=region')
            .then(({ data }) => {
                setJustRegions(data.map((item) => {
                    return { name: item.name, value: item.id }
                }))
            })
            .catch((err) => { })
    }, [updater])

    //change category data
    function handleSubmitCountry(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        formData.append("type", "country");

        axiosAdmin.post(`/crc`, formData)
            .then((res) => {
                setUpdater(res);
                successToast("Success");
            })
            .catch((err) => {
                setUpdater(err);
                errorToast("Error");
            });
    }

    function handleSubmitRegion(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        formData.append("type", "region");
        formData.append("crc_id", regionId.crc_id);

        axiosAdmin.post(`/crc`, formData)
            .then((res) => {
                setUpdater(res);
                successToast("Success");
            })
            .catch((err) => {
                setUpdater(err);
                errorToast("Error");
            });
    }

    function handleSubmitCity(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        formData.append("type", "city");
        formData.append("crc_id", cityId.crc_id);

        axiosAdmin.post(`/crc`, formData)
            .then((res) => {
                setUpdater(res);
                successToast("Success");
            })
            .catch((err) => {
                setUpdater(err);
                errorToast("Error");
            });
    }

    const handleDelete = (id) => {
        axiosAdmin.delete(`/crc/${id}`)
            .then((res) => {
                setUpdater(res);
                successToast("Success");
            })
            .catch((err) => {
                errorToast("Error");
            })
    }

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 min-h-screen">
            <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5" >
                <div className="bg-slate-100 flex flex-col gap-5">

                    <div className="w-full rounded-xl bg-white  p-5 border-slate-300 border-2 shadow-md">
                        <form
                            onSubmit={(e) => { handleSubmitCountry(e) }}
                            encType="multipart/form-data"
                        >
                            <div className="flex flex-col border-b border-slate-300 pb-4 mb-4">
                                <p>
                                    {t("country")}
                                </p>
                                <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                                    <TextInput
                                        inputName={"name"}
                                        inputSetter={() => { }}
                                        placeholder={t("name")}
                                    />
                                    <FileInput
                                        inputName={"img"}
                                        name={t("img")}
                                    />
                                    <TextInput
                                        inputName={"currency"}
                                        inputSetter={() => { }}
                                        placeholder={t("currency")}
                                    />
                                    <button
                                        type="submit"
                                        className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start mt-3">
                                        {t("add")}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form
                            onSubmit={(e) => { handleSubmitRegion(e) }}
                            encType="multipart/form-data"
                        >
                            <div className="flex flex-col border-b border-slate-300 pb-4 mb-4">
                                <p>
                                    {t("region")}
                                </p>
                                <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                                    <TextInput
                                        inputName={"name"}
                                        inputSetter={() => { }}
                                        placeholder={t("name")}
                                    />
                                    <SelectInputValueId
                                        options={justCountries}
                                        inputName={"crc_id"}
                                        inputSetter={handleRegionCrc}
                                    />
                                    <button
                                        type="submit"
                                        className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start mt-3">
                                        {t("add")}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form
                            onSubmit={(e) => { handleSubmitCity(e) }}
                            encType="multipart/form-data"
                        >
                            <div className="flex flex-col border-b border-slate-300 pb-4 mb-4">
                                <p>
                                    {t("city")}
                                </p>
                                <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                                    <TextInput
                                        inputName={"name"}
                                        inputSetter={() => { }}
                                        placeholder={t("name")}
                                    />
                                    <SelectInputValueId
                                        options={justRegions}
                                        inputName={"crc_id"}
                                        inputSetter={handleCityCrc}
                                    />
                                    <button
                                        type="submit"
                                        className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start mt-3">
                                        {t("add")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="w-full flex flex-col gap-3">
                        {countries.map((item) => {
                            return (
                                <CountryDropper
                                    key={item.id}
                                    item={item}
                                    justCountries={justCountries}
                                    justRegions={justRegions}
                                    handleDelete={handleDelete}
                                    setUpdater={setUpdater}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddCountries