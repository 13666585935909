import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import ParentDropper from "./ParentDropper";
import axiosAdmin from "../../../../api/apiAdmin";
import { useEffect, useState } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const AddParents = () => {
    const { t } = useTranslation()
    const [categories, setCategories] = useState([]);
    const [updater, setUpdater] = useState(null);
    const [depth, setDepth] = useState({
        deep: 0
    });
    const [parents, setParents] = useState([]);
    const [parentId, setParentId] = useState({
        category_id: 0
    })

    const handleParentId = (value, inputName) => {
        setParentId({
            category_id: value
        })
    }

    const handleDepth = (value, inputName) => {
        setDepth({ deep: value })
    }

    useEffect(() => {
        axiosAdmin.get(`/category?deep=${depth.deep - 1}`)
            .then(({ data }) => {
                var tmp = data.map((item) => {
                    return { name: item.name, value: item.id }
                })
                setParents(tmp);
            })
            .catch((err) => { })
    }, [depth])

    useEffect(() => {
        axiosAdmin.get('/category')
            .then(({ data }) => {
                setCategories(data);
            })
            .catch((err) => { })
    }, [updater])

    const handleDelete = (id) => {
        axiosAdmin.delete(`/category/${id}`)
            .then((res) => {
                setUpdater(res);
                successToast("Success");
            })
            .catch((err) => {
                errorToast("Error");
            })
    }

    function handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        if (parentId.category_id !== null) {
            formData.append("category_id", parentId.category_id);

        }

        axiosAdmin.post(`/category`, formData)
            .then((res) => {
                setUpdater(res);
                successToast("Success");
            })
            .catch((err) => {
                setUpdater(err);
                errorToast("Error");
            });
    }

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 min-h-screen">
            <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5" >
                <div className="bg-slate-100 flex flex-col gap-5">

                    <div className="w-full rounded-xl bg-white p-5 border-slate-300 border-2 shadow-md">
                        <form
                            onSubmit={(e) => { handleSubmit(e) }}
                            encType="multipart/form-data"
                        >
                            <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-2 justify-end items-end">
                                <TextInput
                                    name={t("depth")}
                                    inputName={"deep"}
                                    inputSetter={handleDepth}
                                    type="number"
                                    placeholder={t("depth")}
                                />
                                <TextInput
                                    name={t("name")}
                                    inputName={"name"}
                                    inputSetter={() => { }}
                                    placeholder={t("name")}
                                />
                                <SelectInputValueId
                                    name={t("parent")}
                                    options={[
                                        {
                                            name: t("withoutParent"),
                                            value: null,
                                        },
                                        ...parents
                                    ]}
                                    inputName={"category_id"}
                                    inputSetter={handleParentId}
                                />
                                <TextInput
                                    name={t("index")}
                                    inputName={"sort"}
                                    inputSetter={() => { }}
                                    type="number"
                                    placeholder={t("index")}
                                />
                                <TextInput
                                    name={t("language")}
                                    inputName={"language"}
                                    inputSetter={() => { }}
                                    type="text"
                                    placeholder={"GE,RU,EN"}
                                />
                                <button
                                    type="suubmit"
                                    className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start md:mt-8">
                                    {t("add")}
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="w-full flex flex-col gap-3">
                        {categories.map((item) => {
                            return (
                                <ParentDropper
                                    key={item.id}
                                    item={item}
                                    handleDelete={handleDelete}
                                    setUpdater={setUpdater}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default AddParents