import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../MainContext/MainContext";

const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const [toggleSwitcher, setToggleSitcher] = useState(false);
  const [currenLanguage, setCurrentLanguage] = useState({});
  const { setApiLanguage } = useContext(PrimaryContext);
  const [langData, setLangData] = useState([]);

  //current language handler
  const handleCurrentLang = (object) => {
    setCurrentLanguage({
      name: object.name,
    });
    localStorage.setItem("GADA_LANG", object.name);
    setTimeout(function () {
      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    const lang = localStorage.getItem("GADA_LANG");
    axiosClient.get("/languages/service").then(({ data }) => {
      setCurrentLanguage({ name: lang ? lang : data[0] });
      setLangData(
        data.map((item) => {
          return { name: item };
        })
      );
    });
  }, []);

  useEffect(() => {
    setApiLanguage(currenLanguage.name);
  }, [currenLanguage]);

  return (
    <div className="relative z-400">
      <div
        className="flex gap-1 items-center bg-white rounded-lg cursor-pointer
            px-2 py-1 border border-[#0d84ff]"
        onClick={() => {
          setToggleSitcher((prev) => !prev);
        }}
      >
        <p className="text-sm font-semibold w-6 uppercase text-[#0d84ff]">
          {currenLanguage.name}
        </p>

        <BiChevronDown
          className={`text-2xl font-semibold text-[#0d84ff] transition-all ${
            toggleSwitcher && "rotate-180"
          }`}
        />
      </div>
      {toggleSwitcher && (
        <div
          className={`absolute top-full right-0 bg-white z-40
                grid rounded-lg translate-y-[5px] border border-[#0d84ff] ${
                  langData.length > 3
                    ? "grid-cols-3 w-[165px]"
                    : "grid-cols-1 w-full"
                } ${
            langData.length > 9 ? "h-[120px] overflow-y-scroll scrollbar" : ""
          } `}
        >
          {langData.map((item, index) => {
            return (
              <div
                className="flex gap-2 items-center justify-center bg-white hover:bg-slate-100 cursor-pointer
                            rounded-lg py-2"
                key={index}
                onClick={() => {
                  handleCurrentLang(item);
                  setToggleSitcher(false);
                }}
              >
                <p className="text-sm font-semibold uppercase text-[#0d84ff]">
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
