import { useState } from "react";
import QuestionMark from "../QuestionMark";
import { BiChevronDown } from "react-icons/bi";
import CheckBox from "../CheckBox";
import RadioButton from "../RadioButton";
import SelectComponent from "../selectFolder/SelectComponent";
import CounterOne from "../counterFolder/CounterOne";
import CounterTwo from "../counterFolder/CounterTwo";
import CounterThree from "../counterFolder/CounterThree";
import CounterFour from "../counterFolder/CounterFour";
import DateInputOne from "../dateInputFolder/DateInputOne";
import DateInputTwo from "../dateInputFolder/DateInputTwo";
import TimeInput from "../TimeInput";
import TextArea from "../TextArea";
import FileUpload from "../FileUpload";
import JustText from "../JustText";
import JustCounter from "../counterFolder/JustCounter";
import NextCounterFour from "../counterFolder/NextCounterFour";
import NextCounterJustOne from "../counterFolder/NextCounterJustOne";
import NextCounterOne from "../counterFolder/NextCounterOne";
import { ContactField } from "../ContactField";
import Linkk from "../simpleLinks/Link";
import YoutubeLink from "../simpleLinks/YoutubeLink";
import TextWithoutColor from "../TextWithoutColor";

const OtherDropdown = ({ item, id, data = [], parentName }) => {
  const [flag, setFlag] = useState(false);

  return (
    <div className="rounded-xl my-3">
      <div
        className={`w-full p-4 cursor-pointer border border-slate-200 rounded-xl
            flex items-center justify-between ${
              flag && "rounded-b-none"
            } bg-orange-100`}
        onClick={() => {
          setFlag((prev) => !prev);
        }}
      >
        <p className="font-semibold">{item?.name}</p>
        <div className="flex items-center gap-2">
          {(item?.img || item?.description) && (
            <QuestionMark
              imgLink={item?.img}
              redactorText={item?.description}
            />
          )}
          <div className="w-8 h-8 rounded-full bg-[#0d84ff]">
            <BiChevronDown
              className={`w-full h-full text-white duration-300 ${
                flag && "rotate-180"
              }`}
            />
          </div>
        </div>
      </div>
      {flag && (
        <div className="transition-all">
          <div className="p-4 border border-slate-200 border-t-0 rounded-lg rounded-t-none">
            {data.map((item, index) => {
              return (
                <>
                  {item?.type === "1" && (
                    <CheckBox
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "2" && (
                    <RadioButton
                      item={item}
                      parentId={id}
                      childId={index}
                      optionsId={id}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "3" && (
                    <SelectComponent
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "4" && (
                    <CounterOne
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "5" && (
                    <CounterTwo
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "6" && (
                    <CounterThree
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "7" && (
                    <CounterFour
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "8" && (
                    <DateInputOne
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "9" && (
                    <DateInputTwo
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "10" && (
                    <TimeInput
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}

                  {item?.type === "11" && (
                    <FileUpload item={item} parentId={id} childId={index} />
                  )}
                  {item?.type === "13" && (
                    <TextArea
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "14" && <JustText item={item} />}
                  {item?.type === "17" && (
                    <JustCounter
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "18" && (
                    <NextCounterJustOne
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "19" && (
                    <NextCounterFour
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "20" && (
                    <NextCounterOne
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                    />
                  )}
                  {item?.type === "21" && <ContactField item={item} />}
                  {item?.type === "22" && <Linkk item={item} />}
                  {item?.type === "23" && <YoutubeLink item={item} />}
                  {item?.type === "24" && <TextWithoutColor item={item} />}
                  {item?.type === "25" && (
                    <CheckBox
                      item={item}
                      parentId={id}
                      childId={index}
                      parentName={parentName}
                      needPriceInText={true}
                    />
                  )}
                  {item?.type === "26" && (
                    <RadioButton
                      item={item}
                      parentId={id}
                      childId={index}
                      optionsId={id}
                      parentName={parentName}
                      needPriceInText={true}
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherDropdown;
