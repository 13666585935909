import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCartCheck, BsEye, BsShare, BsX } from "react-icons/bs";
import ReactToPrint from "react-to-print";
import { PrimaryContext } from "../components/MainContext/MainContext";
import logo from "../assets/img/logo.png";

const OrdersCard = ({ data }) => {
  const { t } = useTranslation();
  const [toggleDetails, setToggleDetails] = useState(false);
  const pdfRef = useRef(null);
  const { selectedCountries } = useContext(PrimaryContext);

  const [detailData, setDetailData] = useState([]);
  const [mainData, setMainData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    setMainData(data);
    setDetailData(JSON.parse(data.code));

    JSON.parse(data.code).map((detail) => {
      setTotalPrice((prev) => prev + parseInt(detail.price));
    });
  }, [data]);

  return (
    <div className="w-full">
      <div
        className="bg-white py-3 px-5 border border-slate-300 rounded-lg flex 
            items-center max-lg:flex-wrap max-lg:gap-x-[1%] max-lg:gap-y-2 max-lg:rounded-[20px]"
      >
        <div className="w-[5%] max-lg:hidden">
          <BsCartCheck className="text-[#0d84ff] font-bold text-2xl" />
        </div>
        <div className="w-[20%] max-lg:w-full">
          <p className="text-[#0d84ff]">
            {mainData.id}: {mainData?.service?.name}
          </p>
        </div>
        <div className="w-[10%] max-lg:w-[49%]">
          <p>10/23/1221</p>
        </div>
        <div className="w-[15%] max-lg:w-[49%]">
          <p>
            {t("done")}
            {/* {t("isNotDone")} */}
          </p>
        </div>
        <div
          className={`w-[15%] max-lg:w-[49%] ${
            true ? "text-green-400" : "text-red-400"
          }`}
        >
          <p>
            {t("paid")}
            {/* {t("notPaid")} */}
          </p>
        </div>
        <div className="w-[15%] max-lg:w-[49%]">
          <p>{totalPrice} $</p>
        </div>
        <div className="w-[20%] flex justify-end max-lg:w-full">
          <div
            onClick={() => {
              setToggleDetails((prev) => !prev);
            }}
            className="bg-[#0d84ff] text-white flex gap-2 items-center justify-center py-2 px-4 rounded-lg cursor-pointer shrink-0
                        max-lg:w-full"
          >
            <BsEye />
            <p>{t("detailedInformation")}</p>
          </div>
        </div>
      </div>
      {toggleDetails && (
        <div
          className="w-screen h-screen fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-80
                max-sm:p-5"
        >
          <div className="bg-white w-[50%] rounded-2xl max-lg:w-[70%] max-sm:w-full">
            <div className="flex items-center justify-between py-4 px-8">
              <h1 className="text-2xl max-sm:text-[18px] font-bold ">
                <span className="text-[#0d84ff]">
                  {t("detailedInformation")}
                </span>
              </h1>
              <div className="flex items-center gap-2">
                <ReactToPrint
                  trigger={() => (
                    <div className={`cursor-pointer text-[#0d84ff]`}>
                      <BsShare />
                    </div>
                  )}
                  content={() => {
                    // Create a wrapper div to hold both original and additional content
                    const wrapperDiv = document.createElement("div");

                    // Create additional HTML content
                    const additionalContentStart =
                      document.createElement("div");
                    additionalContentStart.innerHTML = `<img class="w-[120px] p-2" src="${logo}"></img>`;

                    // Create additional HTML content
                    const additionalContentEnd = document.createElement("div");
                    additionalContentEnd.innerHTML = `<div class="w-full flex items-center justify-end p-2" ><p>
                        ${t("cost")}: ${99999} ${selectedCountries.currency}
                        </p></div>`;

                    // Append the additional content to the wrapper
                    wrapperDiv.appendChild(additionalContentStart);

                    // Clone the original content into the wrapper
                    wrapperDiv.appendChild(pdfRef.current.cloneNode(true));

                    // Append the additional content to the wrapper
                    wrapperDiv.appendChild(additionalContentEnd);

                    // Return the modified content
                    return wrapperDiv;
                  }}
                ></ReactToPrint>
                <BsX
                  className="text-red-500 text-3xl cursor-pointer"
                  onClick={() => {
                    setToggleDetails((prev) => !prev);
                  }}
                />
              </div>
            </div>
            <div className="h-[300px] overflow-x-auto scrollbar">
              <div ref={pdfRef}>
                {detailData.map((detail, index) => {
                  return (
                    <div key={index}>
                      <div className="w-full h-[1px] bg-slate-300"></div>
                      <div className="py-2 px-8">
                        <p className="text-green-400 font-semibold">
                          {detail?.parentName}{" "}
                        </p>
                        <p>{detail?.name}</p>
                        {/* 15 X 2 = 30 */}
                        <p className="text-[#64748B]">{detail?.price}</p>
                      </div>
                    </div>
                  );
                })}
                <div className="w-full h-[1px] bg-slate-300"></div>
                <div className="w-full py-2 px-8 flex flex-col gap-2 justify-end items-end">
                  <div>
                    <p>პირი: სახელი</p>
                    <p>ტელეფონი: {mainData?.phone}</p>
                    <p>ელ-ფოსტა: {mainData?.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[1px] bg-slate-300"></div>
            <div className="py-4 px-8">
              <div className="bg-[#0d84ff] py-2 px-5 rounded-lg relative ">
                <p className="text-white">{t("cost")}</p>
                <div
                  className="border border-slate-300 rounded-[30px] 
                                absolute right-[-5px] top-[50%] py-4 px-6 translate-y-[-50%] flex 
                                items-center justify-center dark-gray-bg"
                >
                  <p className="text-green-500 font-bold"> {totalPrice} $</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersCard;
