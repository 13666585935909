import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, } from "react";
import FullNavigation from '../navigations';
import LoginLayout from "../pages/account/login";
import AddQuestions from "../pages/addQuestions/AddQuestions";
import AddParents from "../pages/addParents/AddParents";
import AddCountries from "../pages/addCountries/AddCountries";
import 'react-toastify/dist/ReactToastify.css';
import UserPageLayout from "../pages/AdminsPage/userPageLayout";
import InnerUsersPage from "../pages/AdminsPage/innerUsersPage";
import InnerQuestions from "../pages/addQuestions/InnerQuestions";

const AdminRouter = () => {
    //get current page url
    const location = useLocation();
    //correct navigations
    const navigate = useNavigate();

    //correct navigations
    useEffect(() => {
        if (location.pathname === "/admin") {
            navigate('login')
        }
    }, [])

    return (
        <div >
            {location.pathname.split("/")[2] !== "login" && <FullNavigation />}
            <Routes>
                <Route path="login" element={<LoginLayout />} />
                <Route path="add-parents" element={<AddParents />} />
                <Route path="add-countries" element={<AddCountries />} />
                <Route path="admins" element={<UserPageLayout />} />
                <Route path="admins/:usersId" element={<InnerUsersPage />} />
                <Route path="add-questions" element={<AddQuestions />} />
                <Route path="add-questions/:questionsId" element={<InnerQuestions />} />
            </Routes>
        </div >
    )
}

export default AdminRouter;