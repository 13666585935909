import { useState, useEffect, useContext } from "react";
import CounterPart from "./CounterPart";
import QuestionMark from "../QuestionMark";
import { PrimaryContext } from "../../MainContext/MainContext";
import { BsX } from "react-icons/bs";

const JustCounter = ({
  item,
  parentId,
  childId,
  parentName,
  needDelete = null,
  handleDelete,
  counters,
}) => {
  const [dataValue, setDataValue] = useState([
    {
      id: 1,
      name: "+2",
      value: "",
    },
  ]);

  const [price, setPrice] = useState(0);
  const { handleAddChosedData, handleDeleteChosedData, selectedCountries } =
    useContext(PrimaryContext);

  useEffect(() => {
    const isValid = !dataValue.some((item) => [0, ""].includes(item.value));
    if (isValid) {
      handleAddChosedData({
        id: `${parentId}${childId}`,
        name: item?.name,
        price: dataValue[0].value * price,
        dontAdd: price ? false : true,
        compareId: `${parentId}${childId}`,
        parentName: parentName,
        priceString: price
          ? `${dataValue[0].value} * ${price} = ${dataValue[0].value * price}`
          : dataValue[0].value,
      });
    } else {
      handleDeleteChosedData(`${parentId}${childId}`);
    }
  }, [dataValue]);

  useEffect(() => {
    try {
      setDataValue([
        {
          id: 1,
          name: item?.price.split("-")[1],
          value: 0,
        },
      ]);
      setPrice(item?.price.split("-")[0]);
    } catch {}
  }, []);

  return (
    <div
      className={`flex justify-between w-full border-b border-slate-300 pb-3 mb-3 
      max-sm:gap-2  gap-2 max-sm:flex-col ${price ? "flex-col" : ""}`}
    >
      <div className="flex items-center gap-2 max-sm:w-full max-sm:justify-between">
        <p className="mt-1">{item?.name}</p>
        <div className="flex gap-2 items-center">
          {needDelete && counters && counters[0].id !== childId && (
            <BsX
              onClick={() => {
                handleDelete(childId);
              }}
              className="text-red-500 text-lg  cursor-pointer"
            />
          )}
          {(item?.img || item?.description) && (
            <QuestionMark
              imgLink={item?.img}
              redactorText={item?.description}
            />
          )}
        </div>
      </div>
      <div
        className={`flex items-center gap-5 
      ${
        price ? "max-sm:grid max-sm:grid-cols-2 max-sm:gap-2 max-sm:w-full" : ""
      }
      `}
      >
        <div>
          <CounterPart
            dataValue={dataValue}
            setDataValue={setDataValue}
            addStyle={"max-sm:w-full"}
          />
        </div>
        {price ? (
          <>
            <div className="p-1 bg-[#F5F5F5] flex gap-1 rounded-3xl items-center justify-center min-w-[160px]">
              {price}{" "}
              {`${selectedCountries.currency ? selectedCountries.currency : ""}
          `}
            </div>
            <div className="p-1 bg-[#F5F5F5] flex gap-1 rounded-3xl items-center justify-center min-w-[160px]">
              {dataValue[0].value * price}{" "}
              {`${selectedCountries.currency ? selectedCountries.currency : ""}
          `}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JustCounter;
